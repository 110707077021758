.content-history {
    left: -473px!important;
    width: 210px!important;
    position: absolute!important;
    left: 440px!important;
    padding-top: 6px!important;
    padding-bottom: 6px!important;
}

.content-history.drop-inner {
    padding: 2px 10px;
    cursor: pointer;
}
.content-history .drop-data {
    border: 0;
    background: rgba(0,0,0,0);
    font-size: 14px;
    color: #4d4f83;
    width: 100%;
    text-align: left;
    margin-bottom: 6px;
    padding: 0 15px;
    cursor: pointer;
}
.active-view {
    background-color: #88cfff94;
}
.add-button {
    position: fixed;
    right: 25px;
}
#fade-menu-acc {
    top: 40px !important;
}

#fade-menuPeriod-acc {
    top: 40px !important;
}

#bookID-acc {
    width: 230px;
    float: left;
    margin: 0;
    background-color: #798bf1;
    color: white;
}

#periodID-acc {
    width: fit-content;
    margin: 0;
    background-color: #798bf1;
    color: white;
}

.TimePeriodSelector{
    margin-left: 227px;
}
#deleteButton {
    margin-right: 20px;
}
.MuiTypography-h6 {
    font-family: Roboto!important;
    font-weight: 500;
}
.MuiDialogContent-root {
    font-family: Roboto!important;
    font-weight: 500;
}
.MuiTypography-body1 {
    font-family: Roboto!important;
    font-weight: 500;
}
.MuiButton-text {
    font-family: Roboto!important;
    font-weight: 500;
}
.MuiMenu-paper {
    font-family: Roboto!important;
    font-weight: 500;
}
.MuiMenuItem-root {
    font-family: Roboto!important;
    font-weight: 500;
}
.MuiInputBase-root {
    font-family: Roboto!important;
    font-weight: 500;
}

@media screen and (min-width: 280px) and (max-width: 319px) {
    .TimePeriodSelector{
        margin-left: 0px;
    }
}

@media screen and (max-width: 320px) {
    .TimePeriodSelector{
        margin-left: -50px;
    }
}

@media screen and (min-width: 321px) and (max-width: 501px) {
    .TimePeriodSelector{
        margin-left: 0px;
    }
}