@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700&display=swap');

body {
   font-family: 'Plus Jakarta Sans', sans-serif;
}

.container {
   max-width: 1280px;
}

.btn {
   transition: 0.5s;
}
.btn.btn-primary {
  background: #0963ed;
  color: #fff;
}

/* .btn:hover {
   opacity: 0.7;
} */

.btn.btn-primary:hover {
  background: #074fbe;   
}

.btn.btn-primary:focus {
  background: #0643a1; 
}

.navbar .nav-link {
  font-size: 14px;
  margin: 0 15px;
  color: #000;
}

input.form-control {
   box-shadow: none;
}

.form-select:focus {
   outline: 0;
   box-shadow: none;
}

nav.navbar.navbar-expand-lg.navbar-light.scrolled {
   position: fixed;
   width: 100%;
   top: 0;
   left: 0;
   z-index: 9999;
   background: #fff;
}


.footer nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light {
   position: relative;
}

/* nav.navbar.navbar-expand-lg.navbar-light {
  z-index: 9;
} */
.navwrap {
   position: relative;
   display: flex;
   justify-content: space-between;
   width: 100%;
   align-items: center;
}

.footer .navbar {
   padding-bottom: 0;
   background-color: #F0F1F5 !important;
   position: relative;
   display: block;
}
.top-btn,.profile-submenu {
   z-index: 9;
}
.btn-sign {
  font-size: 14px;
  color: #0963ED;
  padding: 9px 17px;
  border: 1px solid #0963ed;
  width: 160px;
  height: 50px;
  line-height: 28px;
  transition: 0.5s;
}
.btn-sign:hover {
  border: 1px solid #0963ED;
  background-color: #074fbe;
  color: #0963ED !important;
}
a.btn-sign.in.btn:focus {
  background-color: #cbdaf4;
}
a.btn-sign.in.btn:hover{
  background-color: #dee6f4 !important;
  border: 1px solid #0963ED;
}
.shape6 {
  position: absolute;
  bottom: -498px;
  right: -13px;
}
a.dropdown-item {
   font-size: 14px;
}

.up.btn {
   color: #fff !important;
   background: #0963ED;
}
.up.btn:hover {
  border: 1px solid #0963ED;
  background-color: #074fbe;
}

a.btn-sign.in.btn {
   margin-right: 15px;
}

/* .dropdown-toggle::after {} */

.dropdown-toggle::after {
   display: inline-block;
   margin-left: 9px;
   vertical-align: 0;
   content: url(img/drop-arrow.png);
   background-repeat: no-repeat;
   border-top: 0;
   border-right: 0;
   border-bottom: 0;
   border-left: 0;
}

.navbar-expand-lg .navbar-collapse {
   justify-content: center;
}

.introwrap {
  position: relative;
  display: block;
  margin-top: 14px;
  margin-bottom: 60px;
}

.introwrap h1 {
   font-size: 48px;
   font-weight: 300;
   line-height: 53px;
   letter-spacing: 0.10000000149011612px;
   text-align: left;
   margin-bottom: 24px;
}

.intro-text-heading  p {
  font-size: 48px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  margin-bottom: 47px;
}

.intro-text-heading  p strong{
  color: #0963ED;
}

.inro-image {
  position: relative;
  display: block;
  text-align: center;
  z-index: 8;
}

.hm-btnwraper {
  position: relative;
  display: flex;
}
.hm-btnwraper .btn {
  max-width: 234px;
}
.intro-text {
  position: relative;
  display: block;
  margin-left: 0;
  margin-top: 78px;
}
.intro-bg {
  position: absolute;
  z-index: 1;
  top: -188px;
  right: -33px;
}
.intropara {
  position: relative;
  display: block;
  margin-bottom: 60px;
}
.intropara p {
  font-size: 20px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.10000000149011612px;
  
}

.btn-get-start {
  font-size: 16px;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  width: 312px;
  height: 60px;
  border-radius: 16px;
  margin-right: 25px;
}
.engage-image img {
   border-radius: 20px;
}

.engage-image {
   position: relative;
   display: block;
   margin-top: 55px;
   margin-bottom: 65px;
}
.engage-content-box {
  position: relative;
  display: block;
  max-width: 645px;
  margin: 0 auto 40px auto;
  text-align: center;
  color: #171717;
}
.engage-content-box h2 {
  font-size: 11px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 3px;
  text-align: center;
  opacity: 0.48;
  text-transform: uppercase;
  margin-bottom: 0;
}

.engage-content-box h3 {
  font-size: 35px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0.10000000149011612px;
  color: #043174;   
}

.engage-content-box p {
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   text-align: center;
}
.ecwrap.container {
  max-width: 1200px;
}
.ec-box {
  position: relative;
  display: block;
  text-align: center;
  max-width: 320px;
  margin: 0 auto;
}
.ec-box img {
  margin-bottom: 40px;
}
.ec-box h4 {
   margin-bottom: 16px;
   font-size: 11px;
   font-weight: 500;
   line-height: 32px;
   letter-spacing: 3px;
   text-align: center;
   text-transform: uppercase;
   opacity: 0.48;
}

.ec-box h5 {
  font-size: 22px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.10000000149011612px;
}

.ec-box p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.10000000149011612px;
}

section.plans-wrap {
   position: relative;
   display: block;
}

.plans-wrap>.container {
   max-width: 1300px;
   margin: 0 auto;
   background: #F0F1F566;
   border-radius: 48px;
   padding: 50px 50px;
}

.page-name {
   color: #0963ED;
}

.plan-header {
   position: relative;
   display: block;
   text-align: center;
   margin-bottom: 32px;
}

.plan-header h3 {
   font-size: 11px;
   font-weight: 500;
   line-height: 32px;
   letter-spacing: 3px;
   text-transform: uppercase;
}

.plan-header h4 {
  font-size: 48px;
  font-weight: 700;
  line-height: 61px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  color:#043174 ;

}

.plan-header h5 {
   font-size: 18px;
   font-weight: 300;
   line-height: 40px;
   letter-spacing: 0.10000000149011612px;
}

section.plan-box-wrap {
   position: relative;
   display: block;
   margin-top: 50px;
   margin-bottom: 112px;
}


.planbox {
  position: relative;
  display: block;
  background: #F0F1F5;
  border-radius: 24px;
  padding: 32px 32px 16px 32px;
  margin-bottom: 30px;
  transition: 0.3s;
}
.plan-tick .form-switch .form-check-input {
   opacity: 1;
   background-image: url(../assets/img/download.svg);
   background-color: #E8E8E8;
}

.plan-tick .form-switch .form-check-input:checked {
   background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}

.planbox h3 {
   font-size: 26px;
   font-weight: 600;
   line-height: 40px;
   letter-spacing: 0.10000000149011612px;
   text-align: left;
}

.planbox h4 {
   font-size: 18px;
   font-weight: 300;
   line-height: 40px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 8px;
}

.p-pricewrap {
   position: relative;
   display: flex;
   margin-bottom: 5px;
   min-height: 75px;
}

.p-pricewrap span {
   font-size: 18px;
   font-weight: 600;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
}


.p-pricewrap h5 {
   font-size: 48px;
   font-weight: 300;
   line-height: 64px;
   letter-spacing: 0.10000000149011612px;
}

.p-pricewrap h6 {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   margin-left: 18px;
   margin-top: 30px;
}

.free-pricewrap {
   font-size: 3rem;
   font-weight: 100;
   line-height: 20px;
   letter-spacing: 0.10000000149011612px;
   margin: 25px auto 41px auto;
   text-align: left;
}


button.btntry {
   width: 100%;
   height: 60px;
   border-radius: 16px;
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   text-align: center;
   background: #0963ED;
}

.plan-list-wrap {
   position: relative;
   display: block;
   margin-top: 20px;
   margin-bottom: 12px;
}

.plan-list ul {
   padding: 0;
}

.plan-list-wrap h6 {
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   text-align: left;
   color: #0963ED;
   display: inline-block;
   position: relative;
}

.plan-list-wrap h6 span {
   color: #171717;
}

.plan-list-wrap h6:after {
   position: absolute;
   content: '';
   display: block;
   background: url(./img/arrow-right.png);
   width: 8px;
   height: 12px;
   top: 11px;
   right: -20px;
}


.plan-list {
   position: relative;
   display: block;
   border-top: 1px solid #C7CFDD;
   padding-top: 32px;
   margin-top: 38px;
}


.plan-list ul li {
   font-size: 16px;
   font-weight: 400;
   letter-spacing: 0.10000000149011612px;
   list-style: none;
   background-image: url(./img/check_circle.png);
   background-repeat: no-repeat;
   background-position: left center;
   padding-left: 34px;
   margin-bottom: 16px;
}

/* .planbox.active {
   border: 1px solid #171717;
   background: #fff;
   box-shadow: 4px 4px 0px 0px #171717;
}
.planbox:hover {
  border: 1px solid #171717;
  background: #fff;
  box-shadow: 4px 4px 0px 0px #171717;
} */

.our-plan-title {
   position: relative;
   display: block;
   text-align: center;
}


.our-plan-title form {
   max-width: 310px;
   margin: 0 auto;
   display: inline-flex;
}

.our-plan-title h4 {
   font-size: 32px;
   font-weight: 300;
   line-height: 48px;
   letter-spacing: 0.10000000149011612px;
   text-align: center;

}
.pricing-head {
   padding-top: 0!important;
   padding-bottom: 0!important;
   margin: 0;
}
.pricing-head h4{
   padding-top: 0!important;
   padding-bottom: 0!important;
   margin-top: 0!important;
   margin-bottom: 0!important;
}
/* .our-plan-title .form-check-label {
   font-size: 18px;
   font-weight: 300;
   letter-spacing: 0.10000000149011612px;
} */

.our-plan-title .form-check-label {
   font-size: 18px !important;
   font-weight: 300 !important;
   letter-spacing: 0.10000000149011612px;
   line-height: 43px !important;
}

.plan-tick input#custom-switch {
   margin-top: 7px;
}

.tbl-compare-plan .table-striped>tbody>tr:nth-of-type(odd)>* {
   --bs-table-bg-type: #f9f9fb;
}

.plan-tick .form-floating {
   padding: 8px;
}
.plan-tick {
   position: relative;
   display: block;
   margin-bottom: 75px;
}

.tbl-compare-plan .table>:not(caption)>*>* {
   border: 0;
}

.tbl-compare-plan th {
   text-align: left;
   background: #f9f9fb;
   font-weight: 600;
}

.tbl-compare-plan td {
   text-align: left;
}

.tbl-compare-plan tr td {
   font-weight: 500;
   font-size: 18px;
}

.tbl-compare-plan tr td {
   vertical-align: middle;
   padding: 1px 0;
}

.tbl-compare-plan .table-striped>tbody>tr td:first-child {
   text-align: left;
}

.tbl-compare-plan .table-striped>tbody>tr td:last-child {
   padding-right: 42px;
}

.tbl-compare-plan .table-striped>thead>tr th:last-child {
  padding-right: 42px;
  width: 24%;
}

.faq-col2 {
   position: relative;
   display: block;
   margin-top: 31px;
   max-width: 705px;
}
tr.pricerow {
  border-bottom: 1px solid #C7CFDD;
}

.tbl-compare-plan h4 {
   margin-top: 32px;
}

tr.gray td {
   background: #f9f9fb !important;
}

.tbl-compare-plan h6 {
   font-size: 11px;
   font-weight: 500;
   line-height: 32px;
   letter-spacing: 3px;
   text-align: left;
   text-transform: uppercase;
   color: #171717;
   opacity: 0.48;
   padding-left: 40px;
}

.tbl-compare-plan td h5 {
   font-size: 16px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   text-align: left;
   background-image: url(./img/check_circle.png);
   background-repeat: no-repeat;
   padding-left: 38px;
   margin-left: 40px;
   margin-top: 6px;

}
button.btn-save.btn.btn-primary {
   background-color: transparent !important;
}

section.hm-faq {
   position: relative;
   display: block;
   margin-top: 120px;
}

/* .hm-faq .container {
   max-width: 1104px;
} */

.hm-faq .container {
   max-width: 1280px;
}
.faq-col1 h4 {
   font-size: 11px;
   font-weight: 600;
   line-height: 32px;
   letter-spacing: 2px;
   color: #657899;
}

.faq-col1 h5,
.faq-col1 h6 {
  font-size: 48px;
  font-weight: 700;
  line-height: 61px;
  letter-spacing: 0.10000000149011612px;
  
}

.faq-col1 h5 {
   margin-bottom: 0;
}

.faq-col1 h6 {
   color: #0963ED;
   margin-bottom: 40px;
}

.faq-col1 p {
   font-size: 18px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   max-width: 345px;
   margin-bottom: 34px !important;
}

.btn-showall {
  font-size: 16px;
  font-weight: 400;
  line-height: 8px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  width: 312px;
  height: 60px;
  top: 240px;
  padding: 24px 32px 24px 32px;
  border-radius: 16px;
  border: 1px solid #0963ED;
  background-color: transparent !important;
  color: #0963ED !important;
}

.btn-showall:hover {
  border: 1px solid #0963ED;
  background-color: #dee6f4 !important;
  color: #0963ED !important;
}
.btn-showall:focus {
  border: 1px solid #0963ED;
  background-color: #cbdaf4 !important;
  color: #0963ED !important;
}
.accordion-item,
button.accordion-button {
   background: #F0F1F5 !important;
   border-radius: 18px;
   box-shadow: none !important;
}

.accordion-item {
   margin-bottom: 16px;
   border-radius: 24px !important;
   border: 0;
}

.accordion-item:first-of-type .accordion-button {
   border-top-left-radius: 16px !important;
   border-top-right-radius: 16px !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
   border-bottom-right-radius: 16px !important;
   border-bottom-left-radius: 16px !important;
}

.accordion-button::after {
   flex-shrink: 0;
   width: 21px;
   height: 21px;
   content: "";
   background-image: url(./img/arrow_drop-down.png);
   background-repeat: no-repeat;
}

.accordion-button:not(.collapsed)::after {
   background-image: url(./img/arrow_drop_up.png);
   transform: rotate(0deg);
}

.accordion-item.active {
  background: #fff !important;
  border: 1px solid #659EF4;
}
.active h2.accordion-header {
  background: #fff!important;
  border-radius: 27px;
}
.faq-col2 .accordion-item.active  .accordion-item  {
  background: #fff!important;
}
.faq-col2 .active button.accordion-button {
  font-weight: 600;
  /* background: #fff !important; */
  padding: 30px 32px 12px 32px;
}

section.hm-about {
   position: relative;
   display: block;
   margin-top: 120px;
   margin-bottom: 100px;
}

.hm-about .container {
  max-width: 1280px;
}
.hmabout-content {
   position: relative;
   display: block;
   margin-left: 10px;
   margin-top: 30px;
}

.hmabout-content h4 {
  font-size: 11px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 2px;
  color: #000;
  margin-bottom: 10px;
}

.hmabout-content h5 {
   font-size: 48px;
   font-weight: 700;
   line-height: 61px;
   letter-spacing: 0.10000000149011612px;
   color: #043174;
   margin-bottom: 36px;
}

.hmabout-content p {
   font-size: 16px;
   font-weight: 400;
   line-height: 28px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 40px !important;
}

section.hm-contact-wrap .container {
  background: #0963ED;
  border-radius: 48px;
  max-width: 1310px;
  margin: 0 auto;
  color: #fff;
}

section.hm-contact-wrap {
   position: relative;
   display: block;
   margin-bottom: 130px;
}

.contact-col1 {
  position: relative;
  display: block;
  padding: 50px 50px 75px 70px;
}

.contact-col1 h3 {
  font-size: 11px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 55px;
}

.form-control:focus {
   border-color: transparent;
   outline: 0;
   box-shadow: none;
}

.contact-col1 h4 {
  font-size: 48px;
  font-weight: 700;
  line-height: 61px;
  letter-spacing: 0.10000000149011612px;
  
}
/* .intro-bg {
  position: absolute;
  display: block;
  background: url(http://localhost:3000/static/media/topimagebg.4d3ae692ac96431159b4.png);
  width: 661px;
  height: 882px;
  top: -98px;
  z-index: -1;
} */
.h-order2.col-md-6 {
  position: relative;
}

.contact-col2 {
  background: #fff;
  position: relative;
  display: block;
  max-width: 555px;
  width: 555px;
  margin-top: 48px;
  float: right;
  margin-right: 60px;
  border-radius: 30px;
  padding: 40px 50px;
  color: #000;
  margin-bottom: 48px;
}
.contact-col2 h5{
  font-size: 29px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0.10000000149011612px;
}

button.csubmit {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  padding: 13px 0;
  border-radius: 16px;
}



.form-floating>.form-control-plaintext~label::after, .form-floating>.form-control:focus~label::after, .form-floating>.form-control:not(:placeholder-shown)~label::after, .form-floating>.form-select~label::after {
  background-color: transparent;
}
.contact-col2 .form-control {
  font-size: 16px;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #01112833;
  border-radius: 0;
  padding-left: 3px;
  box-shadow: none;
  height: 40px;
}
.form-floating>label {

  padding: 1rem .75rem 0 0;
}

p.copyright {
   margin-bottom: 0;
}

.footer {
  color: #fff;
  position: relative;
  display: block;
  z-index: 9;
}

.footer .navbar {
   padding-bottom: 0;
   background: #011128 !important;
   border-radius: 24px 24px 0 0;
}

.footer a.nav-link {
   color: #fff;
   transition: 0.5s;
}

.footer a.nav-link:hover {
   color: #00e5ff;
}

.copyright {
   font-size: 11px;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: 0.10000000149011612px;
   padding: 0 28px;
}

.footer .navbar {
   padding-bottom: 0;
}

.modal-dialog {
   max-width: 1065px;
}

.modal-content {
   background: #F0F1F5;
}
.modal-title.h4 {
  position: relative;
  display: flex;
}

.modal-title.h4 {
  position: relative;
  display: flex;
  align-items: center;
}
.modal-title.h4 span {
  font-size: 14px;
  display: block;
  margin: 0 15px;
}

.pb-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout-package-box {
   position: relative;
   display: block;
   margin-bottom: 14px;

}
.checkout-package-box-content {
  border: 1px solid #CACCCB;
  padding: 16px 24px 10px 24px;
  border-radius: 16px;
}

.pb-header .form-check-label {
   font-size: 18px;
   font-weight: 500;
   letter-spacing: 0.10000000149011612px;
   margin-left: 8px;
}
.modal-body input#inline-radio {
  margin-top: 8px;
}


.pb-header .form-check-inline {
   display: flex;
   margin-right: 1rem;
}

.checkout-package-box-content h4 {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   color: #2A7F62;
}

.pb-pointwrap {
  position: relative;
  display: block;
  margin-left: 35px;
}
.modal-header button.btn-close {
  background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
  background-size: 63%;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  box-shadow: none;
}
.modal-content {
  background: #F0F1F5;
  border-radius: 24px;
}
.pb-points li {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   color: #171717;
   opacity: 0.48;
}

.checkout-package-box.active {
   background: #fff;
   box-shadow: 2px 2px 0px 0px #171717;
   border-radius: 18px;
   border: 1px solid #171717;
}

.modify-billing-plan {
   position: relative;
   display: flex;
   margin-top: 45px;
   align-items: center;
   justify-content: space-between;
}

.modify-billing-plan h6 {
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 0;
}

.pop-col2 {
   position: relative;
   display: block;
   background: #fff;
   border-radius: 16px;
   padding: 40px 24px;
}

.strip-wrap {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  border-radius: 16px;
  background: #E8E8E8;
  margin: 0 auto 17px auto;
  padding: 28px;
}
.p-FieldLabel label{
  font-size: 14px;
  margin-bottom: 15px;
}

label.p-FieldLabel.Label {
  margin-bottom: 15px;
  font-size: 14px;
}
label.p-FieldLabel.Label {
  font-size: 14px !important;
  margin-bottom: 10px;
}

.strip-wrap button {
   margin: 8px 20px;
   float: right;
}

.modal-header {
   border: 0;
   padding: 32px 34px 0;
}

.pop-col2 p {
   font-size: 11px;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: 0.10000000149011612px;
   color: #171717;
   opacity: 0.48;
   ;
}

button.btn-pay {
   width: 100%;
   border-radius: 16px;
   height: 60px;
   margin: 0 auto;
}

.pop-col1 {
  position: relative;
  display: block;
  margin-top: 0;
}
.checkout-package-box-wrap {
  position: relative;
  display: block;
  max-width: 465px;
}
.Error {
  font-size: 14px !important;
}
.pop-col1 p {
   margin-bottom: 32px;
}

.pop-col2 h4 {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.10000000149011612px;
}

.thankyou-wrap {
   background-color: #CACCCB;
   position: relative;
   display: flex;
   height: 100vh;
   flex-direction: column;
}

.thankyou-wrap:before {
   position: absolute;
   content: '';
   top: 50%;
   left: 50%;
   width: 893px;
   height: 871px;
   background-image: url(./img/thankyou-bg.png);
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   transform: translate(-50%, -50%);
}


.thankyou-box {
   position: relative;
   display: block;
   width: 552px;
   /* height: 650px; */
   margin: 0 auto;
   border-radius: 24px;
   border: 1px solid #171717;
   padding: 80px 30px;
   -webkit-backdrop-filter: blur(5px);
   backdrop-filter: blur(5px);
   background: #ffffff42;
}

.thankyou-box {
   background-image: url(./img/thakyou-head.png);
   background-repeat: no-repeat;
}

.thankinner {
   position: relative;
   display: block;
}

.thankinner h1 {
   position: relative;
   display: block;
   font-size: 48px;
   font-weight: 300;
   line-height: 64px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 13px;
}

.thankinner p {
   margin-bottom: 20px;
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
}

p.teams {
   margin-bottom: 6px;
}

.thankyou-button-wrap {
   position: relative;
   display: block;
   margin-top: 37px;
}


button.btn-getstarted {
   width: 128px;
   height: 42px;
   border-radius: 8px;
   background: #0963ED;
   font-size: 14px;
   font-weight: 400;
   letter-spacing: 0.10000000149011612px;
   text-align: center;
   margin-right: 15px;

}

button.btn-use {
   width: 164px;
   height: 42px;
   border-radius: 8px;
   border: 1px solid #17171747;
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   color: #171717;
   background-color: #CACCCB;
}

section.content-rows-wrap {
   position: relative;
   display: block;
   margin-top: 196px;
}

.content-rows {
   position: relative;
   display: block;
   max-width: 1320px;
   width: 100%;
   margin: 0 auto 145px auto;
}

.content-rows-image {
   position: relative;
   display: block;
   margin-bottom: 65px;
}

.content-rows-image img {
   border-radius: 40px;
}

.content-rows h3 {
   font-size: 26px;
   font-weight: 600;
   line-height: 40px;
   letter-spacing: 0.10000000149011612px;
   color: #171717;
}

.content-rows p {
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   color: #171717;
}

.content-rows-inner {
   position: relative;
   display: block;
   max-width: 975px;
}

.faq-col2.default {
   position: relative;
   display: block;
   margin-bottom: 130px;
}

section.hm-contact-wrap.exgap {
   margin-bottom: 87px;
}

button.accordion-button {
   font-weight: 600;
}

section.content-rows-wrap.gfix {
   margin-top: 0;
}

.content-rows.gfix {
   margin: 104px auto 145px 85px;
}


.boiler .content-rows-inner {
   margin-bottom: 64px;
}

.faq-col1.boiler {
   margin-left: 85px;
   margin-bottom: 24px;
}

.faq-col2.boiler {
   margin-left: 85px;
   margin-bottom: 140px;
}

.content-wrap {
   position: relative;
   display: block;
}

.home-redirect {
  position: fixed;
  top: 5px;
  right: 15px;
}
.home-redirect .b-link{
  font-size: 20px;
  font-weight: bold;
}
.login-wrap {
   position: relative;
   display: block;
   max-width: 508px;
   width: 100%;
   border-radius: 24px;
   margin: 0 auto;
   /* height: 652px */

}
.content-wrap.login {
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  margin-top: 38px;
  /* height: 100vh; */
}

.login-wrap .form-check .form-check-input {
   width: 24px;
   height: 24px;
}

.loginbox {
   position: relative;
   display: block;
   /* padding: 35px 90px 25px 90px; */
   background-color: #ffffff9e;
   -webkit-backdrop-filter: blur(10px);
   backdrop-filter: blur(10px);
   border: 1px solid #C7CFDD;
   border-radius: 24px;
   background-image: url(../assets/img/login-box-top.png);
   background-position: right top;
   background-repeat: no-repeat;
   margin-bottom: 30px;
}

/* .loginbox form {
  background-color: transparent;
  box-shadow: none;
} */
form {
   background-color: transparent;
   box-shadow: none;
}

.loginwrap-inner {
   position: relative;
   display: block;
   padding: 10px 90px 25px 90px;
   margin-top: 30px;
}
.loginbox-form.recover {
   position: relative;
   display: block;
   margin-top: 30px;
}
.login-wrap:before {
   position: absolute;
   content: '';
   display: block;
   top: 50%;
   left: 57%;
   background: url(./img/login-bg.png);
   width: 704px;
   height: 407px;
   background-repeat: no-repeat;
   transform: translate(-50%, -50%);
   z-index: -1;
}

.loginbox h5 {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   margin-top: 16px;
   margin-bottom: 5px;
}

.loginbox-form .form-control {
   border: 0;
   border-bottom: 1px solid #C7CFDD;
   border-radius: 0;
   padding-left: 0;
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   background: none;
   box-shadow: none;
   height: 47px;
}

.loginbox-form label.form-check-label {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
}

.btn-sign-in {
   width: 240px;
   height: 42px;
   border-radius: 8px;
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   position: relative;
   letter-spacing: 0.10000000149011612px;
   text-align: center;
   margin: 0 auto;
   display: block;
}

.btn-wrap {
   position: relative;
   display: flex;
   justify-content: space-between;
   margin-top: 19px;
}

a.b-link {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   text-decoration: none;
}

.login-footer {
   position: relative;
   display: flex;
   background: #F0F1F5;
   padding: 16px 25px;
   border-radius: 0 0 24px 24px;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.social-sign-in {
   font-size: 14px;
   font-weight: 500;
   line-height: 16px;
   letter-spacing: 0em;
   text-align: left;
   box-shadow: 0px 1px 1px 0px #0000002B;
   background-color: #FFFFFF;
   Width: 234px;
   text-align: center;
   margin-bottom: 20px;
   color: #0000008A;
   text-decoration: none;
   padding: 12px 30px 12px 59px;
   background-repeat: no-repeat;
   background-position: 6% center;
   border: 1px solid transparent;
   transition: 0.5s;
}

.social-sign-in.google {
   background-image: url(../assets/img/google-login-icon.png);
}

.social-sign-in.microsft {
   background-image: url(../assets/img/winloging-icon.png);
   margin-bottom: 0;
}

.social-sign-in:hover {
   border: 1px solid #8C8C8C;
}

button.btn-sign-in.recover {
   margin-top: 45px;
   margin-bottom: 10px;
}

.recover-buttons {
  margin-bottom: 50px;
}
.login-footer.reg button {
   max-width: 235px;
   width: 100%;
   margin-bottom: 12px;
   transition: 0.5s;
   box-shadow: 0px 1px 1px 0px #0000002B !important;
}

.login-footer.reg button:hover {
   border: 1px solid #8C8C8C !important;
}

.forgotpw-wrap {
   position: relative;
   display: block;
   background: #F0F1F5;
   height: 100vh;
   padding-bottom: 121px;
   height: 100%;
}

.forgotpw-wrap .container {
   max-width: 940px;
}

.forgot-box-wrap {
   position: relative;
   display: block;
   margin-top: 65px;
}

.forgot-box-wrap h1 {
   font-size: 32px;
   font-weight: 300;
   line-height: 48px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 85px;
}



.title-line {
   position: relative;
   display: block;
   border-bottom: 1px solid #CED6E0;
   margin-bottom: 40px;
}

.title-line h2 {
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   display: inline-block;
   padding: 0 30px;
   margin-bottom: 0;
   border-bottom: 2px solid #0963ED;
   padding-bottom: 15px;
}

.forgot-box-wrap .title-line h2 {
   padding-bottom: 10px;
}

.forgot-box {
   position: relative;
   display: block;
   max-width: 943px;
   border-radius: 16px;
   background-color: #FFFFFF;
   padding-bottom: 64px;
}

.forgot-box-head {
   position: relative;
   display: flex;
   justify-content: space-between;
   padding: 35px 30px;
}

.forgot-box-head h3 {
   font-size: 18px;
   font-weight: 600;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 0;
}

.forgot-box-head {
   position: relative;
   display: flex;
   justify-content: space-between;
   padding: 35px 30px;
   border-bottom: 1px solid #CED6E0;
   align-items: center;
}

.forgot-text {
   position: relative;
   display: block;
   margin-left: 35px;
   margin-top: 25px;
}

.forgot-text .form-control {
   border: 0 !important;
   border-bottom: 1px solid #C7CFDD !important;
   border-radius: 0;
   max-width: 320px;
   box-shadow: none;
}

button.req-password {
   font-size: 14px;
   padding: 10px 24px;
}

.forgot-text p {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   margin-top: 47px;
}

.mailfix {
   display: flex;
   align-items: center;
}

.forgot-text button.btn-close {
   opacity: 0.2 !important;
   left: -31px;
   position: relative;
}

.content-wrap.gray {
   background: #F0F1F5;
   border-radius: 20px 20px 0 0;
}

.faq-header {
   position: relative;
   display: block;
   padding-top: 90px;
   margin-bottom: 140px;
}

.f-header h1 {
   font-size: 32px;
   font-weight: 300;
   line-height: 48px;
   letter-spacing: 0.10000000149011612px;
}

.f-header p {
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
}

.faq-header .container {
   max-width: 1145px;
}

.headblock {
   position: relative;
   display: block;
   margin-left: 40px;
}

.fh-head-info {
   position: relative;
   display: block;
   width: 576px;
   padding: 32px;
   border-radius: 24px;
   background-color: #C7CFDD;
}

.fh-head-info h2 {
   font-size: 18px;
   font-weight: 600;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
}

.fh-head-info p {
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
}

.btn-getstart {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   text-align: center;
   padding: 7px 75px;
}

.fh-head-info img {
   margin-top: 26px;
}

.f-header.col1 {
   margin-top: 30px;
}

.faqbox-wrap h4 {
   font-size: 48px;
   font-weight: 300;
   line-height: 64px;
   letter-spacing: 0.10000000149011612px;
}

.faqbox-wrap p {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   text-align: right;
   opacity:1;
}


.faq-left-column .accordion-button:after {
   width: 8px;
   height: 12px;
   background-size: cover;
   background-image: url(./img/faq-arrow.png) !important;
}
.faq-left-column .accordion-button:not(.collapsed)::after {
   width: 12px;
   height: 8px;
   background-size: cover;
   background-image: url(./img/faq-down.png) !important;
}

.faq-left-column button.accordion-button {
   background: #fff !important;
   border-radius: 8px !important;
   padding: 18px 27px 19px 27px;
}

.faq-left-column .accordion-item:first-of-type .accordion-button {
   border-top-left-radius: 8px !important;
   border-top-right-radius: 8px !important;
}

.faq-left-column .accordion-flush .accordion-item:first-child {
   background: #fff !important;
   border-radius: 8px 8px !important;
}

.fh-head-info .mobile {
   display: none !important;
}

.faq-left-column .accordion-flush .accordion-item {
   background: #fff !important;

}

.faq-left-column .accordion-item {
  border-radius: 6px !important;
  border: 0;
  margin-bottom: 8px;
}

.btn-faq {
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   padding: 7px 0 7px 16px;
   width: 100%;
   margin-bottom: 10px;
   background: #fff;
   border: 1px solid #fff;
   color: #000;
   text-align: left;
   transition: 0.5s;
}

.btn-faq:hover,
.btn-faq.act {
   background: #F0F1F5;
   border: 1px solid #F0F1F5;
   color: #000;
}

button.btn-faq:last-child {
   margin-bottom: 0;
}

.faq-left-column .accordion-body {
   padding-top: 0;
}

.mainfaq p {
   text-align: left;
   margin-bottom: 0;
}

.faq-right-column {
   position: relative;
   display: block;
   background: #fff;
   border-radius: 16px;
   padding: 32px;
}

.faq-right-column h4 {
   font-size: 26px;
   font-weight: 600;
   line-height: 40px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 16px;
}

.faq-right-column h5 {
   font-size: 18px;
   font-weight: 500;
   line-height: 36px;
   letter-spacing: 0.10000000149011612px;
}

.mainfaq h2.accordion-header .accordion-button {
   padding-left: 50px;
}

.mainfaq h2.accordion-header .accordion-button {
   padding-left: 50px;
   /* background: #fff !important; */
   background: #F0F1F5 !important;
   border-radius: 8px 8px 0 0 !important;
   font-size: 16px;
   color: #171717;
   font-style: normal;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.1px;
   padding-bottom: 0;
}

.faq-left-column .btn.btn-primary:focus, .faq-left-column .btn.btn-primary:hover {
   background: #d9e0eb;
   color: #171717 !important;
}

.mainfaq .accordion-button:not(.collapsed){
   color: #171717 !important;
}

.mainfaq .accordion-collapse.collapse.show .accordion-body {
   background: #F0F1F5 !important;
   border-radius: 0 0 8px 8px;
}

.mainfaq .accordion-body {
   padding-left: 50px;
   padding-top: 0;
}

.mainfaq button.accordion-button.collapsed {
   border-radius: 16px !important;
   background: #fff !important;
}



.accordion-item,
.mainfaq button.accordion-button {
   border-radius: 16px !important;
   background: transparent !important;
}

.faq-col2 .accordion-item {
   background: #F0F1F5 !important;
}

.faq-col2 button.accordion-button {
   font-weight: 600;
   padding: 30px 32px 12px 32px;
}

.faq-col2 .accordion-item .accordion-body {
  padding: 0px 32px 24px 32px;
  line-height: 30px;
}
.tbl-compare-plan tr.gray {
  padding: 27px  0 16px 0;
  position: relative;
  display: block;
}

.faq-col2 button.accordion-button.collapsed {
   padding: 29px 32px;
}

.mainfaq .accordion-button::after {
   width: 13px;
   height: 8px;
   background-image: url(./img/faq-down.png) !important;
   position: absolute;
   top: 20px;
   left: 24px;
   background-size: cover;
}

.faqsection2 {
   position: relative;
   display: block;
   padding-bottom: 140px;
}

.profile-submenu {
   position: relative;
   display: block;

}

.profile-submenu .dropdown-toggle::after {
   display: none;
}

.profile-submenu .nav-item {
   background-image: url(./img/account_user.png);
   background-repeat: no-repeat;
   background-position: 83% center;
   padding: 11px 19px 10px 21px;
}

.profile-submenu .nav-link {
   color: #0963ED;
   margin: 0 34px 0 0;
   position: relative;
   display: block;
   top: 0;
   margin-right: 13px;
   padding-right: 33px;
}

.profile-submenu .nav-item.show.dropdown {
   background-color: #F0F1F5;
}

.profile-submenu .dropdown-menu[data-bs-popper] {
   top: 100%;
   left: -99px;
   margin-top: var(--bs-dropdown-spacer);
   width: 295px;
   box-shadow: 0px 2px 4px 0px #00000040;
   border: 0;
}

.profile-submenu a.dropdown-item {
   /* margin-bottom: 10px; */
   padding: 14px 18px 13px 50px;
   background-position: 4% center;
   background-repeat: no-repeat;
}

a.ps.dropdown-item {
   background-image: url(./img/setting.png);
}

a.si.dropdown-item {
   background-image: url(./img/subcription.png);
}

a.um.dropdown-item {
   background-image: url(./img/user.png);
}

.content-wrap-.dash {
   position: relative;
   display: block;
   background: #F0F1F5;
}

.dash-sidebar {
   position: relative;
   display: block;
   padding-top: 50px;
   border-right: 1px solid #CED6E0;
   height: 100%;
}

.sidelink {
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   color: #171717;
   padding: 6px 46px;
   margin-bottom: 15px;
   background-repeat: no-repeat;
   background-position: 5% center;
}

.sidelink.active {
   color: #0963ED;
   border-left: 4px solid #011128;
}

a.sidelink.ps {
   background-image: url(./img/setting.png);
}

a.sidelink.active.ps {
   background-image: url(./img/setting-current.png);
}

a.sidelink.si {
   background-image: url(./img/subcription.png);
}

a.sidelink.active.si {
   background-image: url(./img/subcription-current.png);
}



a.sidelink.um {
   background-image: url(./img/user.png);
}

/* .sidebanner {
  position: relative;
  top: 366px;
  display: block;
} */

.sidebanner {
   position: relative;
   top: 292px;
   display: block;
}

.breadcrumb-item a {
   text-decoration: none;
}

.breadcrumb-item+.breadcrumb-item::before {
   content: var(--bs-breadcrumb-divider, ">");
}

.dash-content {
   position: relative;
   display: block;
   margin-top: 60px;
   margin-left: 60px;
}

.dash-content h2 {
   font-size: 32px;
   font-weight: 300;
   line-height: 48px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 16px;
}

.quick-info-bar span {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   text-align: left;
   margin-right: 40px;
}

.dashtabs {
   position: relative;
   display: block;
   padding-bottom: 240px;
}

span.mailcheck {
   padding-left: 35px;
   background-image: url(./img/tick.png);
   background-repeat: no-repeat;
   padding-bottom: 4px;
}

.quick-info-bar {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.dashtabs .nav-link {
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   border-radius: 0;
   color: #000 !important;
   padding: 5px 32px;
}

.dashtabs .tab-content-header {
   position: relative;
   display: flex;
   justify-content: space-between;
   padding: 19px 32px 17px 32px;
   border-bottom: 1px solid #CED6E0;
   margin-bottom: 50px;
   align-items: center;
}

.dashtabs .nav-link.active {
   border-bottom: 2px solid #0963ED !important;
   background: transparent !important;
   border: 0;
}

.dashtabs li.nav-item {
   margin-right: 11px;
}

.tab-header-btnwrap .btn {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   padding: 7px 29px;
   /* margin-left: 15px; */
}

.tab-content-wrap {
   position: relative;
   display: block;
   border-radius: 16px;
   background: #fff;
}

.tab-content-header h3 {
   font-size: 18px;
   font-weight: 600;
   line-height: 39px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 0;
}

.tab-content-header {
   position: relative;
   display: flex;
   justify-content: space-between;
   padding: 35px 32px 30px 32px;
   border-bottom: 1px solid #CED6E0;
   margin-bottom: 50px;
}

.tab-contents-inner {
   position: relative;
   display: block;
   padding: 0px 32px 32px;
}


.tab-contents-inner .form-control, .tab-contents-inner .form-select {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #C7CFDD;
  max-width: 320px;
  padding-left: 0;
}
.tab-content-header.changepw {
  margin-bottom: 30px;
}

.form-floating>label {
   color: #1717179e;
}

.tab-contents-inner .form-select {
   background-image: url(./img/select-arrow.png);
   background-repeat: no-repeat;
   background-position: 97% 34px;
   background-size: auto;
}

.ps-wrap.col {
   position: relative;
   display: block;
   max-width: 320px;
}

.tab-contents-inner button.btn-close {
   position: absolute;
   top: 22px;
   right: 0;
   opacity: 0.2;
}

.tab-contents-inner input#custom-switch {
   width: 56px;
   height: 32px;
}


.form-check label.form-check-label {
   margin-left: 10px;
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
}
.checkout-package-box-content label.form-check-label {
  font-weight: 500;
}
.pb-header h6 {
  font-size: 18px;
  font-weight: 500;
}
.loginbox-form .form-check label.form-check-label {
   margin-left: 25px;
   font-size: 14px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
}

/* 
.form-check label.form-check-label {
   margin-left: 25px;
   top: 4px;
   position: relative;
} */

.loginbox-form .form-check label.form-check-label {
   margin-left: 0 !important;
   top: -1px;
   position: relative;
   left: 15px;
}

.security-info {
   position: relative;
   display: block;
   text-align: left;
   margin-left: 83px;
   margin-top: 10px;
}

.security-info p {
   text-align: left;
   text-indent: -56px;
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
}

.security-info p {
  text-align: left;
  text-indent: 0;
  color: #171717;
  opacity: 0.64;
}
.tab-contents-inner.secure {
  margin-top: -20px;
}
.tab-contents-inner button.btn.btn-light {
   border: 1px solid #C7CFDD;
}


.tab-content-header h3 span {
   color: #0963ED;
}

.plan-col1 {
   position: relative;
   display: block;
}

.plan-info {
   position: relative;
   display: block;
   margin-bottom: 41px;
}

.plan-info h3 {
   font-size: 18px;
   font-weight: 600;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   border-bottom: 1px solid #011128;
   padding-bottom: 16px;
   margin-bottom: 15px;
}

.plan-row h4 {
   margin-bottom: 0;
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
}

.plan-row {
   position: relative;
   display: flex;
   justify-content: space-between;
   border-bottom: 1px solid #C7CFDD;
   align-items: center;
   padding: 11px 0;
}

.plan-row h5 {
   font-size: 18px;
   font-weight: 300;
   line-height: 40px;
   letter-spacing: 0.10000000149011612px;
   text-align: right;
   margin-bottom: 0;
}

.plan-row h5 span {
   color: #0963ED;
}

.plan-info p {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   opacity: 0.5;
   margin-top: 24px;
}


.tab-content-footer {
   position: relative;
   display: block;
   border-top: 1px solid #C7CFDD;
   padding: 20px 30px;
}

.tab-footer-btnwrap {
   position: relative;
   display: flex;
   justify-content: flex-end;
}

.tab-footer-btnwrap button.btn.btn-primary {
   font-size: 14px;
   padding: 9px 24px;
}

.plan-col2 {
   position: sticky;
   display: block;
   top: 20px;
}

.right-sidebar {
   border: 1px solid #CACCCB;
   border-radius: 8px;
   padding: 24px;
}

.right-sidebar h5 {
   font-size: 18px;
   font-weight: 600;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   padding-left: 30px;
   background-image: url(./img/help.png);
   background-repeat: no-repeat;
   background-position: left 7px;
   margin-bottom: 44px;

}

.right-sidebar .nav-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.10000000149011612px;
  border-radius: 0;
  color: #0963ED !important;
  margin-bottom: 44px;
  padding: 0px;
}

.dashtabs.t2 {
   padding-bottom: 115px;
}

.updateplan-box {
   position: relative;
   display: block;
}

.updateplan-row-wrap {
   position: relative;
   display: block;
   margin-bottom: 56px;
}

.updateplan-row-head {
  position: relative;
  display: flex;
  border-bottom: 1px solid #011128;
  margin-bottom: 20px;
}

.updateplan-row-head h4 {
   font-size: 18px;
   font-weight: 600;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
}

.updateplan-row-head h5 {
   font-size: 18px;
   font-weight: 600;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
}

.updateplan-row-head-col1 {
   position: relative;
   display: block;
   width: 50%;
}

.updateplan-row-head-col2 {
   position: relative;
   display: block;
   width: 25%;
   text-align: center;
}

.updateplan-row-head-col3 {
   position: relative;
   display: block;
   width: 25%;
   text-align: center;
}

.updateplan-row h6 {
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 0;

}

.updateplan-row h4 {
   font-size: 18px;
   font-weight: 300;
   line-height: 40px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 0;

}

.updateplan-row {
   position: relative;
   display: flex;
   border-bottom: 1px solid #C7CFDD;
   margin-bottom: 0;
   align-items: center;
}

.updateplan-row-col1 {
   position: relative;
   display: block;
   width: 50%;
   padding: 0px 0 25px 0;
}
.rgap .updateplan-row-col1 {
   position: relative;
   display: block;
   width: 50%;
   padding: 11px 0;
}

.updateplan-row-col2 {
   position: relative;
   display: block;
   width: 25%;
   text-align: center;
}

.updateplan-row-col3 {
   position: relative;
   display: block;
   width: 25%;
   text-align: center;
}

button.btn-select-plan {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   text-align: center;
   padding: 7px 23px;
}

.btn-outline-primary{
  border: 1px solid #0963ED;
  color: #0963ED;
  background: #fff;
  transition: 0.5s;
}
.btn-outline-primary:hover{
  border: 1px solid #0963ED;
  color: #0963ED;
  background: #dee6f4;
}
.btn-outline-primary:focus{
  border: 1px solid #0963ED;
  color: #0963ED;
  background: #cbdaf4;
}

.btn:first-child:active{
  background: #0643a1;
}

button.btn-select-plan.btn.btn-outline-primary:focus {
  border: 1px solid #0963ED;
  background: #cbdaf4;
  color: #0963ED;
}

.no-border {
   border: 0;
}

.updateplan-row.no-border {
   margin-top: 16px;
}

.updateplan-row h4 span {
   color: #0963ED;
}

p.note {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   opacity: 0.5;
   margin-top: 32px;
}

.updateplan-row-wrap:last-child {
   margin-bottom: 0;
}

button.btn-save-more {
   font-size: 11px;
   font-weight: 400;
   line-height: 0;
   letter-spacing: 0.10000000149011612px;
   box-shadow: 2px 2px 0px 0px #171717;
   border: 1px solid #171717;
   width: 90px;
   height: 24px;
   background: #E8E8E8;
   color: #000;
   margin-bottom: 15px;
}

.bill-info {
   position: relative;
   display: block;
}

.bill-info p {
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   text-align: left;
}

.bill-detail {
   position: relative;
   display: block;
   margin-top: 40px;
}
.bill-detail-row {
  position: relative;
  display: flex;
  margin-bottom: 25px;
}
.bill-detail-row h5 {
   margin-bottom: 0;
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   color: #171717;
   opacity: 0.5;
   min-width: 225px;
}



.bill-detail-row h6 {
   margin-bottom: 0;
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   color: #171717;
}

.payment-history-wrap {
   position: relative;
   display: block;
   margin-bottom: 24px;
   background: #fff;
   border-radius: 16px;
   padding-bottom: 15px;
}

.no-bg {
   background: transparent;
}

.btn-mailcopy {
   position: absolute;
   top: 19px;
   right: 11px;
   width: 24px;
   height: 24px;
   background-image: url(./img/edit.png);
   background-repeat: no-repeat;
   background-color: transparent !important;
   border: 0;
}



.copymail {
   position: relative;
   display: block;
   margin-bottom: 16px;
}

.invoice-download {
   width: 24px;
   height: 24px;
   background-image: url(./img/picture_as_pdf.png);
   background-repeat: no-repeat;
   background-color: transparent !important;
   border: 0;
}

.payment-history-wrap .tab-contents-inner {
   padding: 0;
}

.tab-contents-inner tr th {
  background: #E8E8E8 !important;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.10000000149011612px;
  padding: 16px 7px 16px 17px;
  color: #1717178a;
}

.tab-contents-inner tr td {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   padding: 16px 7px 16px 17px;
}

.payment-history-wrap .tab-contents-inner tr td {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   padding: 12px 7px 12px 17px;
}

.payment-history-wrap .tab-content-header {
   margin-bottom: 0;
}

.tab-contents-inner button.btn.btn-light {
   border: 1px solid #C7CFDD;
}

/* content page */
.contents {
   position: relative;
   display: block;
   height: 100vh;
}

.contents.input-output {
   height: auto;
}

.content-inner {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
}

.content-inner.credit {
  flex-direction: column;
  margin-bottom: 50px;
  min-height: 500px;
}

.login-box {
   position: relative;
   display: block;
   text-align: center;
   max-width: 495px;
}

.login-box h1,
.login-box h2 {
   font-size: 40px;
   font-weight: 300;
   color: #1E1F46;
   margin-bottom: 20px;
   font-family: 'Roboto', sans-serif !important;
}

.login-box h2 {
   display: none;
}

.login-box p {
   font-size: 18px;
   color: #262864;
}

.credit .login-box {
   width: 100%;
}

.login-box:before {
   background: white;
   position: absolute;
   content: '';
   display: block;
   top: -51px;
   left: -657px;
   width: 1038px;
   height: 421px;
   z-index: -1;
}

.login-box:after {
   position: absolute;
   content: '';
   display: block;
   top: -144px;
   right: -52%;
   width: 156px;
   height: 639px;
}

.login-box.register {
   max-width: 530px;
   margin-top: 94px;
}

.login-box.register h4 {
   font-size: 15px;
   color: #262864;
   font-weight: 500;
   margin-bottom: 14px;
}

.login-box.regstep {
   max-width: 650px;
   width: 100%;
   margin-top: 65px;
}

.login-box.regstep:after {
   position: absolute;
   content: '';
   display: block;
   top: -202px;
   right: -31%;
   width: 156px;
   height: 639px;
}

.credit .login-box {
   width: 100%;
}

.attributes label {
   height: 20px;
}

.login.register .form-check {
  padding-left: 0;
  margin-bottom: 15px;
}
.content-wrap.login.register {
   height: auto;
   margin: 60px 0;
}
.verify h5, .verified h5 {
  font-size: 20px;
  font-weight: 700;
}
.sendtext p {
  margin-top: 20px;
  margin-bottom: 20px;
}
.verify h4 {
  font-size: 16px;
  font-weight: 700;
}

.btn-done img {
   max-width: 18px;
}

#title-input {
   min-width: 40vw;
   padding: 0 5px;
   min-height: 50px;
   margin-left: 20px;
}

.styles-module__show__2NBoJ {
   visibility: visible;
   opacity: 0.9;
}

#rmv-btm-margin {
   margin-bottom: 5px;
}

.tag-input-button {
   display: inline-block;
   cursor: pointer;
}

.btn-mrgn-auto {
  padding: 16px 0;
  cursor: pointer;
}
#tag-span {
  border-radius: 20px;
  background: #3e406b;
  color: #ffffff;
  margin-right: 5px;
  margin-bottom: 5px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px 18px;
  font-size: 13px;
}

#close-span {
   border-radius: 50px;
   background: #ffffff;
   color: #3e406b;
   padding: 0 3px;
   margin-left: 3px;
   font-size: 12px;
   font-weight: 900;
   cursor: pointer;
}

#tag-container {
   margin-left: 5px;
   margin-bottom: 10px;
}

.catagory-value {
   display: inline-flex;
}

.catagory-value img {
   margin-left: 5px;
   cursor: pointer;
}

#att-tag-container {
   margin-left: 5px;
   margin-bottom: 2px;
}

.bill-info.phistory {
   padding: 35px 32px 30px 32px;
}

.progress {
   height: 4px;
}

.signin-topwrap {
   position: relative;
   display: block;
   border-radius: 0 0 24px 24px;
}

.signbox-wrap {
   background-color: #F0F1F5;
   position: relative;
   display: block;
   border-radius: 0 0 24px 24px;
   padding: 16px 24px 16px 24px;
   border-radius: 16px;
}

.content-wrap {
   position: relative;
   display: block;
   padding-top: 24px;
}

.signbox-wrap h2 {
   font-size: 18px;
   font-weight: 500;
   line-height: 36px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 16px;
}

.signbox-wrap.credit {
   background-image: url(./img/credit.png);
   background-repeat: no-repeat;
   background-position: 96% 14%;
}

.signbox-wrap.storage {
   background-image: url(./img/storage.png);
   background-repeat: no-repeat;
   background-position: 96% 14%;
}


.sign-text {
   position: relative;
   display: flex;
   justify-content: space-between;
}

.sign-text h4 {
   font-size: 26px;
   font-weight: 600;
   line-height: 40px;
   letter-spacing: 0.10000000149011612px;
}
.sign-text span {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
  opacity: 0.64;
}
/* .btn-getcredit {
   border: 1px solid #C7CFDD;
   font-size: 14px;
   font-weight: 400;
   line-height: 24px;
   letter-spacing: 0.10000000149011612px;
   background-color: #F0F1F5;
   color: #171717;
   padding: 0px 23px;
   max-height: 42px;
} */

.btn-getcredit {
  border: 1px solid #C7CFDD;
  font-size: 14px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.10000000149011612px;
  background-color: #F0F1F5;
  color: #171717;
  padding: 0px 23px;
  max-height: 42px;
  height: 42px;
}

.btn-getcredit-line{
  border: 1px solid #C7CFDD;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  background: transparent !important;
  color: #171717 !important;
}

.stepline-wrap {
   position: relative;
   display: block;
   margin-top: 16px;
}
.signbox-wrap .stepline-wrap {
  margin-bottom: 16px;
}

.projectlist-wrap {
   position: relative;
   display: block;
   background: #F0F1F5;
   padding-top: 62px;
   margin-top: 40px;
}


.projectlist-wrap .container {
   max-width: 1066px;
}

.project-title {
   position: relative;
   display: block;
   margin-bottom: 24px;
}

.project-title h5 {
   font-size: 14px;
   font-weight: 400;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 10px;
}

.project-title h6 {
   font-size: 32px;
   font-weight: 300;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 0;
}


.p-search {
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   background-image: url(./img/search.png) !important;
   background-repeat: no-repeat;
   text-indent: 48px;
   background-position: 20px center !important;
   box-shadow: none !important;
   height: 40px !important;
   margin-top: 25px;
   padding-bottom: 10px;
}


.project-tbl-wrap {
   position: relative;
   display: block;
   background: #F0F1F5;
   padding-bottom: 60px;
}

.project-tbl-wrap table {
   max-width: 1040px;
   margin: 0 auto;
}

.tblinner {
   max-width: 1040px;
   margin: 0 auto;
   background: #fff;
   border-radius: 16px;
   padding: 8px 0 300px 0;
}

.project-tbl-wrap tr th {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   padding-left: 30px;
   /* opacity: 0.48; */
   color: #17171782;
   padding: 18px 30px 30px 30px;
}

.sortingwrap {
   position: relative;
   display: flex;
}

button.optionsbtn.btn.btn-primary {
   background-color: transparent;
   border: 0;
   float: right;
   padding-top: 0;
   padding-bottom: 7px;
}

.sorting.dropdown-toggle:hover img.active {
   display: block;
}

.sorting.dropdown-toggle:hover img.normal {
   display: none;
}

.sorting.dropdown-toggle img {
   position: absolute;
   top: 8px;
}

.sortingwrap h6 {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 0;

}

.sorting.dropdown-toggle:hover {
   background: transparent;
}

/* button.sorting {
   background: transparent !important;
   padding: 0;
   border: 0;
   margin-left: 12px;
} */
button.sorting {
   /* background: transparent !important; */
   padding: 0;
   border: 0;
   margin-left: 12px;
   background: transparent;
   padding: 8px;
   width: 24px;
   height: 24px;
   border-radius: 40px;
   display: flex;
   align-items: center;
   justify-content: center;
}

button.sorting:hover {
   background: #F0F1F5;
   border-color: transparent;
}
.sortingwrap .btn-check:checked+.btn,
.sortingwrap .btn.active,
.sortingwrap .btn.show,
.sortingwrap .btn:first-child:active,
.sortingwrap :not(.btn-check)+.btn:active {
   background-color: #F0F1F5 !important;
   border-color: transparent !important;
}

.sorting.dropdown-toggle::after {
   display: none;
}

.sortingwrap .dropdown-item:focus,
.sortingwrap .dropdown-item:hover {
   background-color: #00000012;
}

.project-tbl-wrap tr td {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.10000000149011612px;
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 0px;
}

img.active {
   display: none;
}

.dropdown img.active {
   display: none;
}

.show.dropdown img.active {
   display: block;
}

.show.dropdown img.normal {
   display: none;
}

.sortingwrap .dropdown-item {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   padding: 12px 16px;
   color: #171717;
   margin: 2px auto;
   max-width: 96%;
}

.sortingwrap .dropdown-menu {
   background: #F0F1F5;
   width: 200px;
   padding-top: 0;
   padding-bottom: 0;
   transform: translate(-162px, 32px) !important;
   box-shadow: 0px 2px 4px 0px #00000040;
}

.mobile-note {
   display: none;
}

.project-list-mobile {
   display: none;
}

.options {
   position: absolute;
   width: 24px;
   height: 24px;
   top: 26px;
   right: 16px;
   background-image: url(./img/more_vert.png);
   background-repeat: no-repeat;
   background-color: transparent;
   border: 0;
}

.loadmore-wrap {
   position: absolute;
   display: block;
   max-width: 90%;
   margin: 0 auto 0 auto;
   bottom: 15px;
   left: 23px;
}

.loadmore-wrap h6 {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   color: #0963ED;
   background-image: url(./img/loadmore.png);
   background-repeat: no-repeat;
   padding-left: 26px;
   background-position: left 11px;
}


a.scroll-top {
   position: fixed;
   display: block;
   width: 64px;
   height: 64px;
   bottom: 115px;
   left: 40px;
   box-shadow: 0px 4px 10px 0px #00000029;
   background-color: #ffffff2b;
   background-image: url(./img/backtop.png);
   background-repeat: no-repeat;
   border-radius: 50px;
   background-position: center;
}

.dash-content.reports {
   margin-left: 20px;
}

.report-result {
   position: relative;
   display: block;
}

.reports-wrap {
   position: relative;
   display: block;
   min-height: 500px;
   /* border-top: 1px solid #CED6E0;
   padding-top: 40px; */
}

.reports-wrap .accordion-item:last-of-type .accordion-button.collapsed {
   /* border-bottom-right-radius: 16px !important; */
   /* border-bottom-left-radius: 16px !important; */
   background: #fff !important;
}

.reports-wrap .accordion-item:first-of-type .accordion-button {
   border-top-left-radius: 16px !important;
   border-top-right-radius: 16px !important;
   background: #fff !important;
   /* border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; */
}

.reports-wrap .accordion-collapse {
   background: #F0F1F5;
}

.report-result .accordion-button::after {
   flex-shrink: 0;
   width: 13px;
   height: 8px;
   content: "";
   background-image: url(./img/faq-down.png);
   background-repeat: no-repeat;
   background-size: contain;
}

.report-result .accordion-body {
   padding: 0;
}

.report-inner {
   position: relative;
   display: block;
   margin-top: 80px;
   margin-bottom: 160px;
}

.report-inner .nav-tabs .nav-link {
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   text-align: center;
   color: #171717;
   border: 1px solid transparent;
}

.report-inner .nav-link.active {
   background: transparent;
   border: 0;
   border-bottom: 2px solid #0963ED !important;
}

.chartwrap {
   position: relative;
   display: block;
   background: #FFFFFF;
   border-radius: 16px;
   max-width: 310px;
   width: 310px;
   height: 360px;
   padding: 40px;
   text-align: center;
   margin-right: 24px;
}

.chartwrap h3 {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
}

.report-inner .tab-content {
   margin-top: 40px;
}

.report-tbl-wrap thead tr th {
   background: #E8E8E8 !important;
   font-size: 11px;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: 0.10000000149011612px;
   padding: 16px 0;
}

.report-tbl-wrap thead th:first-child {
   border-radius: 16px 0 0 0;
}

.report-tbl-wrap thead th:last-child {
   border-radius: 0 16px 0 0;
}

.report-tbl-wrap tr td:first-child {
   padding-left: 17px;
}

.report-tbl-wrap {
   position: relative;
   display: block;
   border-radius: 0 0 16px 16px;
}


.reportcol1 {
   position: relative;
   display: flex;
}

.report-tbl-wrap tr td {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
}

.reportcol2 {
   position: relative;
   display: block;
   padding-bottom: 0;
   background-color: #fff;
   border-radius: 16px;
   min-height: 360px;
}

.btn-download {
   background-image: url(./img/file_download.png);
   width: 24px;
   height: 24px;
   background-color: transparent !important;
   border: 0;
}

.report-tbl-wrap2 {
   position: relative;
   display: block;
   background: #fff;
   border-radius: 16px;
   padding-bottom: 15px;
   margin-bottom: 40px;
}

.report-tbl2-header {
   position: relative;
   display: flex;
   padding: 32px;
   justify-content: space-between;
}

.report-tbl2-header h3 {
   font-size: 18px;
   font-weight: 600;
   line-height: 0;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 16px;
}

.report-tbl2-header h6 {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
}

.tbl-report2 thead tr th:first-child {
   padding-left: 36px;
   width: 27%;
}

.tbl-report2 tr td:first-child {
   padding-left: 36px;
   width: 27%;
}

.tbl-report2 thead tr th:nth-child(2) {
   width: 40%;
}

.tbl-report2 thead tr td:nth-child(2) {
   width: 40%;
}

.tbl-report2 tbody tr td {
   padding: 16px 11px;
}

.tbl-report2 thead tr th {
   font-size: 11px;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: 0.10000000149011612px;
   background: #E8E8E8;
   padding: 16px 8px;
}


.export .chartwrap {
   max-width: 640px;
   width: 640px;
   height: 360px;
}

.reportcol1.export {
   width: 660px;
   display: flex;
   flex-direction: column;
}


.duration-wrap {
   position: relative;
   display: block;
   max-width: 320px;
   margin-bottom: 56px;
}

.duration-wrap select.form-select {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #C7CFDD;
  border-radius: 0;
  background-position: 98% 30px;
  padding-left: 3px;
}

.prompt-engineering-wrap {
   position: relative;
   display: block;
}

.pro-eng-header {
   position: relative;
   display: flex;
   justify-content: space-between;
   margin-bottom: 40px;
}

.btn-group.switcher {
   padding: 6px 5px;
   background: #E8E8E8;
   border-radius: 40px;
}

.switcher button.btn.btn-primary.active {
   border-radius: 40px !important;
   background: #fff;
   color: #171717;
}

.switcher button.btn.btn-primary {
   background: transparent;
   border: 0;
   color: #171717;
   padding: 10px 17px;
   opacity: 1 !important;
   font-size: 14px;
}

.pro-eng-stepwrap {
   position: relative;
   display: flex;
   margin-right: 0;
   padding-right: 152px;
   flex-direction: column;
   text-align: center;
   align-items: center;
}

.pro-eng-stepwrap h3 {
   background: #E8E8E8;
   width: 40px;
   height: 40px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #CACCCB;
   border-radius: 50px;
   font-weight: 400;
   font-size: 20px;
}

.pro-eng-stepwrap h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #17171775;
}
.pk-accordion-wrap button.accordion-button {
  font-size: 18px;
  font-weight: 600;
  color: #171717;
}

.pro-eng-stepwrap:before {
   position: absolute;
   display: block;
   background: #CED6E0;
   width: 142px;
   height: 1px;
   content: '';
   top: 20px;
   left: 47px;
}

.pro-eng-stepwrap.active h3 {
   background: #0963ED;
   color: #fff;
}

.pro-eng-steps {
   position: relative;
   display: flex;
   margin-left: 70px;
}

.pro-eng-stepwrap.active h4 {
   color: #0963ED;
}










.pro-eng-tblwrap {
   position: relative;
   display: block;
   background: #fff;
   border-radius: 16px;
   padding-bottom: 23px;
   margin-bottom: 40px;
}

.pro-eng-tblwrap table thead th:first-child {
   border-radius: 16px 0 0 0;
   padding: 16px 32px;
   width: 18%;
}

.pro-eng-tblwrap table thead th:nth-child(2) {
   width: 41%;
}

.pro-eng-tblwrap table thead th:nth-child(3) {
   width: 7%;
}

.pro-eng-tblwrap table thead th:nth-child(4) {
   width: 24%;
}

.pro-eng-tblwrap table thead th:last-child {
   border-radius: 0 16px 0 0;
}

.pro-eng-tblwrap table thead tr th {
   background: #E8E8E8;
   font-size: 11px;
   font-weight: 400;
   vertical-align: middle;
   line-height: 16px;
   letter-spacing: 0.10000000149011612px;
   color: #171717;
}
.prompt-engineering-wrap thead {
   height: 50px;
}

.pro-eng-tblwrap table tbody td:first-child {
   padding-left: 32px;
}

button.btn-edit {
   position: relative;
   display: block;
   background-image: url(./img/edit.png) !important;
   background-repeat: no-repeat;
   background-color: transparent !important;
   border: 0;
   width: 24px;
   height: 24px;
}

button.btn-add {
   position: relative;
   display: block;
   background-image: url(./img/add.png)!important;
   background-repeat: no-repeat;
   background-color: transparent !important;
   border: 0;
   width: 24px;
   height: 24px;
   margin-top: 20px;
}
button.btntry.btn.btn-primary {
   max-width: 272px;
}
.planbox button.btntry.btn.btn-primary {
   margin-top: 42px;
}
.planbox-active button.btntry.btn.btn-primary {
   margin-top: 10px;
}

button.btn-delete {
   position: relative;
   display: block;
   background-image: url(./img/delete.png) !important;
   background-repeat: no-repeat;
   background-color: transparent !important;
   border: 0;
   width: 24px;
   height: 24px;
}

button.btn-close {
   position: relative;
   display: block;
   background-color: transparent;
   background-image: url(./img/icone-clsoe.png) !important;
   background-repeat: no-repeat;
   background-color: transparent !important;
   border: 0;
   width: 24px;
   height: 24px;
   opacity: 1;
}

button.btn-save {
   position: relative;
   float: left;
   display: block;
   background-image: url(./img/check-tick.png) !important;
   background-repeat: no-repeat !important;
   background-color: transparent !important;
   border: 0;
   width: 24px;
   height: 24px;
   margin-top: 10px;
   margin-left: 3px;
}

.pro-eng-tblwrap table tbody td {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   padding-top: 16px;
   padding-bottom: 16px;
   
}

.pro-eng-tblwrap tr td.text1 p {
   max-width: 525px;
}

.pro-eng-tblwrap tr td.text2 p {
   max-width: 290px;
}

.pro-eng-tblwrap tr td p {
   margin-bottom: 0;
}

.btn-proeng {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   padding: 7px 33px;
   margin-right: 16px;
}

.prompt-eng-wrapper {
   position: relative;
   display: block;
   margin-bottom: 90px;
}

.pro-eng-stepwrap.completed h3 {
   background: #fff;
   border: 1px solid #0963ED;
   color: #0963ED;
   ;
}

.output-wrap {
   position: relative;
   display: block;
   background: #C7CFDD3D;
   border-radius: 24px;
   padding: 33px 24px;
   margin-bottom: 32px;
}

.output-wrap p {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   max-width: 760px;
}

.reports-wrap.subscriptions {
   border: 0;
   padding-top: 0;
}

.subscriptions .report-inner {
   margin-top: 0;
   margin-bottom: 115px;
}

.subscriptions .nav-link {
   padding: 10px 55px;
}

.duration-wrap.sub {
   padding: 32px 32px 0 32px;
   margin-bottom: 30px;
}

.tbl-subscriptions thead tr th:first-child {
   padding-left: 36px;
   width: 27%;
}

.tbl-subscriptions thead tr th {
   font-size: 11px;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: 0.10000000149011612px;
   background: #E8E8E8;
   padding: 16px 8px;
}

.tbl-subscriptions tbody tr td {
   padding: 16px 11px;
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
}

.subscriptions-tbl-wrap {
   position: relative;
   display: block;
   background: #fff;
   border-radius: 16px;
   padding-bottom: 15px;
   margin-bottom: 40px;
}

.tbl-subscriptions thead tr th:first-child,
.tbl-subscriptions tbody tr td:first-child {
   padding-left: 36px;
   width: 15%;
}

.tbl-subscriptions thead tr th:nth-child(3) {
   width: 30%;
}

.chartwrap.sub {
   position: relative;
   display: block;
   background: #FFFFFF;
   border-radius: 16px;
   max-width: 100%;
   width: 100%;
   height: 360px;
   padding: 40px;
   text-align: center;
   margin-right: 0;
}


/* .pro-eng-stepwrap {
  position: relative;
  display: flex;
  margin-right: 0;
  padding-right: 6px;
  flex-direction: column;
  text-align: center;
  align-items: center;
} */

.package-wrapper .pro-eng-steps {
   position: relative;
   display: block;
   margin-left: 0;
}

.package-wrapper .pro-eng-stepwrap {
   position: relative;
   display: flex;
   margin-right: 0;
   padding-right: 79px;
   flex-direction: column;
   text-align: center;
   align-items: center;
   flex: 1 1;
   /* max-width: 350px; */
   float: left;
}

.package-wrapper .pro-eng-stepwrap:before {
   position: absolute;
   display: block;
   background: #CED6E0;
   width: 127px;
   height: 1px;
   content: '';
   top: 20px;
   left: 95px;
}

.package-wrapper .pro-eng-stepwrap.completed:before {
   background: #0963ED;
}

.pro-eng-stepwrap:last-child:before {
   display: none;
}

.pro-eng-stepwrap:last-child {
   padding-right: 0;
}

.pkinfo p {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
}

.pkinfo {
   position: relative;
   display: block;
   margin-bottom: 35px;
}


.reports-wrap.packages .accordion-item:first-of-type .accordion-button {
   border-top-left-radius: 8px !important;
   border-top-right-radius: 8px !important;
   /* background: #fff !important; */
   font-size: 18px;
}

.reports-wrap.packages .accordion-item{
  font-size: 18px;
}
.pk-accordion-wrap .form-floating label {
   font-size: 14px;
}

.pk-accordion-wrap .accordion-body {
   padding: 0;
   background: #fff;
   padding: 0px 24px 24px 24px;
   border-radius: 0 0 8px 8px;
}


.pk-accordion-wrap .accordion-item:first-of-type .accordion-button {
   border-radius: 8px !important;
}

.pk-accordion-wrap .accordion-item:last-of-type .accordion-button.collapsed {
   /* border-bottom-right-radius: 16px !important; */
   /* border-bottom-left-radius: 16px !important; */
   border-radius: 8px !important;
   background: #fff !important;
}
.pk-accordion-wrap button.accordion-button {
  background: #fff !important;
  border-radius: 8px !important;
  font-size: 18px;
  padding: 24px 24px;
  font-weight: 500;
}
.setting-field-wrap.tbl.usermanage {
  margin-top: 24px;
}
.packages .nav-link {
   min-width: 200px;
}
.settings.datainput input.form-check-input {
  width: 24px;
  height: 24px;
}


/* .reports-wrap.packages {
   padding-top: 25px;
} */


.packages .report-inner {
   margin-top: 0;
}

.packages .dash-content h2 {
   font-size: 18px;
   font-weight: 500;
   line-height: 36px;
   letter-spacing: 0.10000000149011612px;
}

.settings.general p {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
}

.settings h3 {
   font-size: 18px;
   font-weight: 600;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 32px;
}

.pk-accordion-wrap .settings.general {
   margin-bottom: 80px;
}

.settings h3 span {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
}
.setting-field-wrap.tbl input.form-control, .setting-field-wrap.tbl .form-select {
  height: auto;
}

.pk-accordion-wrap .settings.general:last-child {
   margin-bottom: 0;
}

.setting-field-wrap .form-select {
   background-image: url(./img/loadmore.png);
   background-size: auto;
   background-repeat: no-repeat;
   background-position: right 32px;
}

.setting-field-wrap thead th {
  background: #E8E8E8;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.10000000149011612px;
  /* opacity: 0.64; */
  color: #6b6b6b;
  padding: 16px;
}

/* .setting-field-wrap .form-control, .setting-field-wrap .form-select {
  border: 0;
  border-bottom: 1px solid #C7CFDD;
  border-radius: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
  margin-bottom: 27px;
  max-width: 320px;
} */

.setting-field-wrap .form-control, .setting-field-wrap .form-select {
   border: 0;
   border-bottom: 1px solid #C7CFDD;
   border-radius: 0;
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 27px;
   max-width: 320px;
   padding-left: 3px;
}
.setting-field-wrap.tbl td select.form-select.form-select {
   background-position: right 17px;
   margin-bottom: 0;
   padding: 6px 20px 6px 7px;
}

.setting-field-wrap.tbl td {
   font-size: 14px;
}
.setting-field-wrap.tbl.usermanage td {
  font-size: 14px;
  vertical-align: middle;
}
.tbl-check {
   position: relative;
   text-align: center;
   top: 10px;
}

.setting-field-wrap.tbl td select.form-select.form-select {
   background-color: transparent;
}
.setting-field-wrap.tbl.pk td select.form-select.form-select {
  width: 166px;
}
.api-key-settings .form-control,
.api-key-settings .form-select {
   border: 0;
   border-bottom: 1px solid #C7CFDD;
   border-radius: 0;
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   max-width: 550px;
}

.btn-next {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   text-align: center;
   padding: 7px 48px 7px 48px;
}

.package-id {
   position: relative;
   display: flex;
   align-items: center;
}

.package-id {
   position: relative;
   display: flex;
   align-items: center;
   flex-direction: column;
}

.package-id span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.package-id h4 {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 0;
   /* width: 46px; */
   text-align: right;
   margin-right: 20px;
}

/* .package-id h5 {
  font-size: 26px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.10000000149011612px;
  margin-bottom: 0;
  word-wrap: break-word;
} */

.package-id h5 {
   font-size: 18px;
   font-style: normal;
   font-weight: 300;
   word-wrap: break-word;
   /* width: 128px; */
   margin-bottom: 0;
}

.package-id h6 {
   font-size: 26px;
   font-style: normal;
   font-weight: 600;
   line-height: 40px;
   letter-spacing: 0.1px;
   margin-bottom: 0;
}

.datainput h5 {
   font-size: 18px;
   font-weight: 600;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   margin-bottom: 45px;
}

.datainput .form-check label.form-check-label {
  margin-left: 13px;
  top: 1px;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
}
.di-tickwrap .form-check {
  /* display: flex; */
  margin-bottom: 20px;
}
.di-col1 h5, .di-col2 h5 {
  margin-bottom: 25px;
}
button.btn-back.btn.btn-light {
   padding: 9px 48px 9px 48px;
   border: 1px solid #C7CFDD;
   margin-right: 15px;
   font-size: 14px;
}
.pk-view table tbody td {
  padding: 20px 32px 4px 32px !important;
}

.di-tickwrap {
   position: relative;
   display: block;
   margin-bottom: 45px;
}

.pk-accordion-wrap .accordion-item .accordion-button.collapsed {
   /* border-bottom-right-radius: 16px !important; */
   /* border-bottom-left-radius: 16px !important; */
   border-radius: 8px !important;
   background: #E8E8E8 !important;
   color: #1717174a;
}

.pk-accordion-wrap .accordion-item:last-of-type .accordion-button.collapsed {
   /* border-bottom-right-radius: 16px !important; */
   /* border-bottom-left-radius: 16px !important; */
   border-radius: 8px !important;
   background: #E8E8E8 !important;
}

.pk-view {
   position: relative;
   display: block;
   /* padding: 24px; */
   background: #fff;
   border-radius: 8px;
}


.pk-view table thead th:first-child {
   /* border-radius: 8px 0 0 8px; */
   padding: 16px 32px;
   /* width: 15%; */
}

/* .pk-view table thead th,
.pk-view table tbody td {
   padding: 16px 32px;
} */
.pk-view table thead th, .pk-view table tbody td {
  padding: 16px 32px;
}

.pk-view table tbody td:first-child {
   padding: 16px 32px;
}

.pk-view table thead th:nth-child(2) {
   width: 21%;
}

.settings .accordion-button::after {
   flex-shrink: 0;
   width: 13px;
   height: 8px;
   content: "";
   background-image: url(./img/faq-down.png);
   background-repeat: no-repeat;
   background-size: contain;
}

.settings .accordion-item:last-of-type .accordion-button.collapsed {
   /* border-bottom-right-radius: 16px !important; */
   /* border-bottom-left-radius: 16px !important; */
   border-radius: 8px !important;
   background: #fff !important;
   color: #171717;
}

.settings .accordion-item:first-of-type .accordion-button {
   border-radius: 8px 8px 0 0 !important;
}

.settings.general .nav-tabs .nav-link {
   font-size: 16px;
   font-weight: 400;
   line-height: 32px;
   letter-spacing: 0.10000000149011612px;
   text-align: center;
   color: #171717;
   border: 1px solid transparent;
}

.settings.general .nav-link.active {
   background: transparent;
   border: 0;
   border-bottom: 2px solid #0963ED !important;
}

.settings .nav-link {
   min-width: 140px;
   color: #000;
}

.setting-field-wrap.tbl .form-check-label,
.setting-field-wrap.tbl h6 {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
}

.setting-field-wrap.tbl .form-check-input {
   width: 24px;
   height: 24px;
}

.usermanage thead tr th:first-child {
   padding-left: 36px;
   width: 9%;
}

.usermanage tr td:first-child {
   padding-left: 36px;
   width: 9%;
}

.settings .nav-link.active {
   background: transparent;
   border: 0;
   border-bottom: 2px solid #0963ED !important;
}

.usermanage thead tr th {
   padding: 16px 0;
}

.usermanage td {
   padding: 16px 0;
}

.usermanage thead tr th:nth-child(2) {
   width: 20%;
}

.usermanage thead tr th:nth-child(3) {
   width: 16%;
}

.usermanage thead tr th:nth-child(4) {
   width: 17%;
}

.usermanage thead tr th:nth-child(5) {
   width: 11%;
}

.user-image {
   position: relative;
   display: flex;
   align-items: center;
}

.setting-field-wrap.tbl h6 {
   position: relative;
   display: block;
   margin-left: 10px;
}

.user-image img {
   border-radius: 50px;
}

.usermanage .form-check-input {
   top: 7px;
   position: relative;
}

p.note {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
}

.btn-generate {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   text-align: center;
   border: 1px solid #C7CFDD;
}

.setting-field-wrap.setting {
   max-width: 80%;
}

.notify {
   position: relative;
   display: block;
}

.notify .form-check-input {
   width: 24px;
   height: 24px;
}
.notify .form-check label.form-check-label {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   color: #171717;
   opacity: 0.8;
   top: 2px;
   position: relative;
   padding-left: 0;
}

.setting-tabs p {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
}

.settings .pk-accordion-wrap .accordion-item .accordion-button.collapsed {
   border-radius: 8px !important;
   background: #fff !important;
   color: #171717;
}

.form-check-input:checked[type=checkbox] {
   --bs-form-check-bg-image: url("./img/check-tick.png");
   background-size: 76%;
}

.form-check-input:checked[type=checkbox] {
   background-color: #fff;
   border-color: #C7CFDD;
   margin-top:3px;
}
.form-check-input:focus {
   border-color: #ccc;
   outline: 0;
   box-shadow: none;
}

input#custom-switch[type=checkbox] {
   width: 53px;
   height: 32px;
}

input#custom-switch:checked[type=checkbox] {
   background-color: #0963ED;
   background-size: contain;
}

.plan-tick .form-check.form-switch {
   display: flex;
   width: 200px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
   transition: background-color 5000s ease-in-out 0s;
}

.prompt-engneering-input {
  width: 100%;
  border-bottom: 1px solid #CED6E0 !important;
  padding: 10px;
  border: 0;
}
.credit-wrap {
  position: relative;
  display: block;
  margin: 10px auto 0 auto;
  width: 370px;
  background: #F2F5F7 0% 0% no-repeat padding-box;
  border-radius: 7px;
  text-align: center;
  padding: 10px 28px;
  margin-bottom: 25px;
}

.copymail input.form-control {
   box-shadow: none;
}

.credit-wrap h5 {
  font-size: 13px;
  font-weight: 700;
  color: #1E1F46;
}

button.btn.tip {
  padding: 0;
  position: relative;
  display: inline-block;
  top: -3px;
  left: 5px;
}

.line {
   width: 320px;
   height: 3px;
   background: #EBEBEB;
   border-radius: 30px;
   position: relative;
   display: block;
}

.fill {
   height: 3px;
   background: #71B64E 0% 0% no-repeat padding-box;
   border-radius: 30px 0px 0px 30px;
   position: absolute;
   top: 0;
   left: 0;
}

.credit-wrap h6 {
   font-size: 10px;
   font-weight: 500;
   color: #76767B;
   margin-top: 8px;
}

.input-details-wrap {
   position: relative;
   display: block;
   max-width: 555px;
   width: 100%;
}

.input-details-wrap h3 {
  color: #262864;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}
.input-form label.form-label {
  color: #262864;
  font-size: 15px;
  font-weight: 700;
}

.input-details-wrap input.form-control {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: none;
  border: 1px solid #EAEAEA !important;
  border-radius: 7px;
  font-size: 16px;
  height: 60px;
}
.input-details-wrap input.form-control:focus {
  border: 1px solid #000 !important;
}

input.form-control::placeholder {
   color: #00000061;
   opacity: 1; /* Firefox */
 }
 
 input.form-control::-ms-input-placeholder { /* Edge 12 -18 */
   color: #00000061;
 }

.catagory-value input.form-control {
   border: 0 !important;
   height: 48px;
}

.catagory-value input.form-control:focus {
   border: 0!important;
}



.input-details-wrap .form-control
.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
   transform: scale(.7) translateY(-.5rem) translateX(.15rem);
}

button.btncancel.btn.btn-light {
  border: 1px solid #C7CFDD;
  background: #fff;
  margin-right: 15px;
  transition: 0.5s;
}

button.btncancel.btn.btn-light:hover {
  border: 1px solid#c7cfdd;
  background:#c7cfdd;
}
button.btncancel.btn.btn-light:focus {
  border: 1px solid #a7aeba;
  background: #a7aeba
}




.input-form h5 {
   color: #636484;
   font-size: 12px;
   font-weight: 400;
   margin-top: 10px;
   margin-bottom: 32px;
}

.security-info p {
   line-height: 32px;
   margin-bottom: 0;
}


.form-select {
   background-color: #FFFFFF;
   box-shadow: none;
   border: 1px solid #EAEAEA;
   border-radius: 7px;
   font-size: 14px;
   height: 50px;
   color: #656161;
}

.no-data select.form-select {
   max-width: 180px;
}

button.btn-setting {
   position: relative;
   display: block;
   border: 0;
   font-size: 15px;
   color: #262864;
   background-color: transparent;
   font-weight: 600;
   margin: 20px 0;
}
.btn-setting span {
  margin-right: 5px;
  font-size: 19px;
}

.btn-wraps {
  margin-top: 20px;
  position: relative;
  display: flex;
}

button.btn.btn-pgenerate {
   position: relative;
   display: block;
   background: #262864 0% 0% no-repeat padding-box;
   box-shadow: 0px 0px 16px #0000000D;
   border-radius: 5px;
   font-size: 16px;
   color: #fff !important;
   font-weight: 500;
   margin: 0;
   padding: 11px 42px;
   max-width: 250px;
   transition: 0.5s;
}

button.btn.btn-reset {
   position: relative;
   display: block;
   box-shadow: 0px 0px 16px #0000000D;
   border: 1px solid #707070;
   font-size: 17px;
   color: #656161 !important;
   font-weight: 500;
   padding: 12px 61px;
   margin-left: 18px;
}

.add-attribute {
   position: absolute;
   display: block;
   width: 60px;
   height: 60px;
   background: #EFEFEF 0% 0% no-repeat padding-box;
   box-shadow: 0px 0px 12px #DFDADA29;
   border: 1px solid #F1EDED;
   border-radius: 7px;
   top: 0;
   right: 21px;
}

.popover-body {
   padding: 1rem 1rem;
   color: #fff;
   font-size: 11px;
   max-width: 205px;
}

.attributes-title p {
   font-size: 11px;
   margin-bottom: 0;
   padding-bottom: 0;
}

.react-tooltop p {
   font-size: 11px !important;

}
.input-details-wrap .form-check label.form-check-label {
  margin-left: 0;
  font-size: 14px;
  line-height: 2px;
}

.tagsInput {
   width: 75%;
   float: left;
   padding: 5px;
   box-shadow: 0px 0px 12px rgba(181, 178, 178, .1607843137);
   border: 1px solid #eaeaea;
   border-radius: 7px;
}

#close-span {
  border-radius: 50px;
  background: #ffffff;
  color: #3e406b;
  padding: 2px;
  margin-left: 7px;
  font-size: 12px;
  font-weight: 900;
  cursor: pointer;
  width: 15px;
  line-height: 9px;
  height: 15px;
  text-align: center;
  display: inline-block;
}

div#attri-cat {
   position: relative;
}

.outset {
   position: relative;
   display: block;
   margin-left: 15px;
   margin-bottom: 15px;
}

.results {
   position: relative;
   display: block;
   margin: 17px 0;
   background: #f5f5f5 0% 0% no-repeat padding-box;
   border: 1px solid #e7e4e4;
   padding: 12px 17px;
   border-radius: 8px;
}

.results h4 {
   font-size: 14px;
   color: #262864;
   font-weight: 500;
   margin-bottom: 0;
}

button.remove-attribute {
   position: absolute;
   display: block;
   border: 0;
   top: 6px;
   background: rgba(0, 0, 0, 0);
   right: 24px;
   width: 20px;
   height: 20px;
}

.output-detail-wrap {
   position: relative;
   display: block;
   width: 70%;
   background: #fff;
   margin-top: 70px;
   padding-top: 50px;
}

.output-title-wrap {
   position: relative;
   display: flex;
   justify-content: space-between;
   margin-bottom: 60px;
}


.output-title-wrap h3 {
   margin-bottom: 0;
   font-size: 31px;
   font-weight: 500;
   color: #1e1f46;
}

.drop-wrap {
   position: relative;
   display: flex;
}

.export-data-wrap {
   z-index: 5;
   position: absolute;
   display: block;
   background: #fcfcfc 0% 0% no-repeat padding-box;
   box-shadow: 0px 0px 11px rgba(0, 0, 0, .0901960784);
   border: 1px solid #a3a3ad;
   border-radius: 6px;
   width: 188px;
   transition: .5s;
   top: 0;
   left: -193px;
}

.export-data-wrap h6 {
   position: relative;
   display: block;
   margin: 9px 12px;
   color: #4d4f83;
   font-size: 14px;
   cursor: pointer;
}

.export-data-wrap h6:after {
   position: absolute;
   content: url(https://eprodx.ai/fc2b4943dbc150c27c28c28b680afbaa.svg);
   display: block;
   top: 0;
   right: 0;
   width: 9px;
   height: 5px;
}

.drop-inner {
   position: relative;
   display: block;
   max-height: 0;
   overflow: hidden;
   transition: .5s;
}

.drop-inner button {
   border: 0;
   background: rgba(0, 0, 0, 0);
   font-size: 14px;
   color: #4d4f83;
   width: 100%;
   text-align: left;
   margin-bottom: 6px;
   padding: 0 15px;
}

button.close-btn {
   position: relative;
   display: block;
   background: #fcfcfc 0% 0% no-repeat padding-box;
   border: 1px solid #a3a3ad;
   border-radius: 6px;
   color: #4d4f83;
   font-size: 14px;
   font-weight: 500;
   padding: 6px 18px;
   margin-left: 15px;
}

.outputrows {
   position: relative;
   display: block;
   border-bottom: 1px solid #e2dddd;
   padding-bottom: 15px;
   margin-bottom: 20px;
}

.outputrows .form-check-input {
  font-size:22px;
  margin-top: 3px;
  margin-left: -25px;
}
.out-put-title h3 {
   color: #262864;
   font-size: 16px;
   font-weight: 500;
}

.out-put-description h4 {
   color: #222121;
   font-size: 14px;
   font-weight: 400;
}


.checklist-row {
   position: relative;
   display: flex;
   justify-content: space-between;
   margin-bottom: 25px;
   align-items: flex-start;
}

.checkwrap {
   position: relative;
   display: block;
   width: 85%;
}

.contorl-box {
   position: relative;
   display: flex;
   align-items: center;
   opacity: 0;
   pointer-events: none;
   transition: .5s;
}

.combin-button {
   position: relative;
   display: flex;
   background: #fff 0% 0% no-repeat padding-box;

   border-radius: 5px;
   padding: 4px 11px;
}
.combin-button .btn-edit img {
  width: 108%;
}
.contorl-box button {
  border: 0;
  background: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, .1607843137);
  width: 28px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}

.open .drop-inner {
   max-height: 300px;
}
.drop-inner button {
   border: 0;
   background: rgba(0, 0, 0, 0);
   font-size: 14px;
   color: #4d4f83;
   width: 100%;
   text-align: left;
   margin-bottom: 6px;
   padding: 8px 15px;
}

.drop-inner button img {
   display: inline-block;
   margin-right: 10px;
}

.form-check-label h5 {
   color: #262864;
   font-size: 14px;
   font-weight: 400;
   margin-bottom: 2px;
   line-height: 23px;
}

.act .contorl-box {
   opacity: 1;
   pointer-events: unset;
}

.btn-edit img {
   width: 144%;
}


button.btn.btn-feedback {
   position: relative;
   display: block;
   box-shadow: 0px 0px 16px rgba(0, 0, 0, .0509803922);
   border: 1px solid #707070;
   font-size: 17px;
   color: #656161 !important;
   font-weight: 500;
   padding: 12px 40px;
   margin-left: 18px;
   margin-bottom: 20px;
}

.close-btn img {
   margin-right: 8px;
}


/* .navbar-toggler-icon {
   display: inline-block;
   width: 18px;
   height: 12px;
   background-image: url(../assets/img/menu-icon.png);
} */

.navbar-toggler.collapsed .navbar-toggler-icon {
  display: inline-block;
  width: 18px;
  height: 12px;
  background-image: url(../assets/img/menu-icon.png);
} 
.navbar-toggler span.navbar-toggler-icon{
  background-image: url(../assets/img/close.png);
  background-size: cover;
  width: 15px;
  height: 15px;
}
.breadcrumb-item+.breadcrumb-item::before {
   margin: 0 15px;
}

.tab-content-wrap.billing .tab-content-header {
   margin-bottom: 40px;
}
section.engage-contents-wrap {
  position: relative;
  display: block;
  padding-bottom: 120px;
}

.billing .tab-header-btnwrap .btn {
   font-size: 14px;
   font-weight: 400;
   line-height: 26px;
   letter-spacing: 0.10000000149011612px;
   padding: 8px 58px;
   margin-left: 15px;
}

.pk-accordion-wrap h2.accordion-header {
   margin-bottom: 0;
}
p.note.set {
   margin-top: 20px;
   margin-bottom: 32px;
}

.strip-wrap button {
  float: none;
  background: #ffffff;
  border: 1px solid #ccc !important;
  padding: 8px 35px;
  margin: 16px 0;
  display: block;
  position: relative;
  transition: 0.5s;
}

.strip-wrap button:hover {
  background: #e6e6e6;
}
.p-FieldError {
  font-size: 12px;
}

.error-field.form-floating .form-control {
  border-bottom: 1px solid #c00 !important;
  position: relative;
  display: block;
  background-image: url(../assets/img/error-icon.png);
  background-repeat: no-repeat;
  background-position: right center;
}
.error-field.form-floating .form-control {
  margin-bottom: 0;
}
.project-details {
  cursor: pointer;
}
.content-history-data {
  cursor: pointer;
  padding: 3px 15px;
}
.content-history-data span{
  cursor: pointer;
  padding: 15px 10px;
  text-decoration: underline;
  text-underline-offset: 6px;
}
.content-history-data img{
  padding-bottom: 5px;
}
.content-history-button {
  margin-bottom: 5px!important;
}
button.btn-content {
  position: relative;
  display: block;
  border: 1px solid #707070;
  font-size: 15px;
  color: #262864;
  background-color: transparent;
  font-weight: 600;
  margin-top: 20px;
  padding: 5px 10px;
  box-shadow: 0px 0px 16px #0000000D;
}
.btn-content span {
 margin-left: 15px;
 margin-right: 5px;
 font-size: 19px;
}
.text-area {
  overflow: hidden;
}
.text-area-count {
  float: right;
}

.workspace {
  position: relative;
  display: block;
  margin-left: 100px;
}
.signbox-wrap.credit {
  margin-right: 23px;
}
.signbox-wrap.credit, .signbox-wrap.storage {
  width: 330px;
}

.signbox-inner-wrap {
  position: relative;
  margin-right: 80px;
  display: flex;
  justify-content: flex-end;
}
.workspace h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #171717;
  opacity: 0.6;
  margin-bottom: 0;
}
.workspace h3 {
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #000000;
  margin-bottom: 0;
}
.dropwrap {
  position: relative;
  display: block;
  max-width: 184px;
  margin-left: -11px;
}

.dropwrap select.form-select {
  border: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
}
.dropwrap select.form-select option {
  padding-left: 15px;
}

.workspace h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: #171717;
  opacity: 0.6;
  margin-bottom: 24px;
}

.projectlist-wrap .p-search {
   margin-top: 0;
   border: 0;
}

.sorting.btn-primary {
  background: transparent;
}

.login-box.regstep.tellmore {
  text-align: left;
}

.tellmore .logo-wrap {
  position: relative;
  display: block;
  text-align: center;
}

.tellmore .logo-title {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 30px;
}

.tellmore input.form-control {
  height: 55px;
}


a.btn-next-step {
  font-size: 14px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0.10000000149011612px;
  color: #fff;
  padding: 0px 23px;
  max-height: 42px;
  height: 42px;
  background: #0963ed;
  display: inline-block;
  text-decoration: none;
  border-radius: 8px;
  transition: 0.5s;
  cursor: pointer;
}

a.btn-next-step:hover {
  background: #074fbe;
}
.cat_name {
  padding: 6px 10px;
  font-size: 20px;
}
.dropdownMultiselect span {
  width: 95%!important;
}
.dropdownMultiselect .ant-select-selection-placeholder {
   padding-left: 25px;
}
.dropdownMultiselect .ant-select-selection-item-content {
   min-width: 61px;
}
.dropdownMultiselect .ant-select-selection-overflow {
   padding-left: 30px;
}
.cat-upgrade-btn {
  margin-right: 10px;
}

.shapes-wrap1 {
  position: relative;
  display: block;
}
.shape1 {
  position: absolute;
  top: -30px;
  left: 30px;
}
.shape2 {
  position: absolute;
  top: -55px;
  right: -33px;
}

.zig-title {
  position: relative;
  display: block;
  margin-left: 60px;
}

.zig-title h3 {
  font-size: 48px;
  font-weight: 700;
  line-height: 61px;
  letter-spacing: 0.10000000149011612px;
  color: #043174;
  margin-bottom: 30px;
}
.zig-title p{
  font-size: 18px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: 0.10000000149011612px;
}

.shapes-wrap2 {
  position: relative;
  display: block;
}

.shape3 {
  position: absolute;
  right: 165px;
  top: 15px;
}

.zig-box.row {
  margin-bottom: 135px;
}

.zig-title.r2 {
  margin-left: 0;
}
.zig-title.r2 h3 {
  margin-top: 88px;
}
.shape4 {
  position: absolute;
  top: 4px;
  left: -36px;
}

.shape5 {
  position: absolute;
  top: -83px;
  right: 21px;
}
/* h6.popular {
  position: relative;
  display: inline-block;
  background: #20318C;
  color: #fff;
  padding: 0px 8px;
  width: 111px;
  border-radius: 20px;
  text-align: center;
  text-transform: uppercase;
  line-height: 28px;
} */

.plan-box-wrap .row {
   align-items: center;
}
h6.popular {
   border-radius: 20px;
   background: #20318C;
   height: 28px;
   width: fit-content;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0 23px;
   color: #FFF;
   font-family: "Plus Jakarta Sans";
   font-size: 13px;
   font-style: normal;
   font-weight: 800;
   line-height: 40px;
   letter-spacing: 0.1px;
   text-transform: uppercase;
   margin-bottom: 22px;
   opacity: 1;
}
.tbl-compare-plan h6.popular {
   background: #0963ed;
}
.planbox-active {
   border-radius: 23px;
   border: 2px solid #000;
   background: #F6FAFF;
   box-shadow: 3px 4px 1px #000;
   padding: 32px 32px 35px 32px;
}

.login.verify .loginwrap-inner {
   display: block;
   margin-top: 30px;
   padding: 15px 90px 25px;
   position: relative;
}

.verify .loginbox {
   text-align: center;
}

.verify .loginbox-form h4 {
   margin: 15px 0;
}

.verified .loginwrap-inner {
   display: block;
   margin-top: 30px;
   padding: 15px 44px;
   position: relative;
   text-align: center;
}
.verified button.btn-sign-in.btn.btn-primary {
   margin-top: 30px;
}

.login-box.regstep.tellmore {
   text-align: left;
   position: relative;
   display: block;
   margin: 30px auto;
}

.loginwrap-inner .btn-wrap {
   max-width: 70%;
   margin: 15px auto;
}

.tab-contents-inner button.btn-close {
   position: absolute;
   top: 14px;
   right: 16px;
   opacity: 0.2;
}
.setting-field-wrap label {
   padding-left: 12px;
}

.form-control:disabled + label{
   left: 15px !important;
}

.setting-field-wrap label {
   left: 0;
}

.font-completed button{
   color: #000 !important;
}

.content-loader-model {
   background-color: #ffff;
}
.pack-description .form-control {
   max-width: 720px!important;
}
.navbar-nav {
   z-index: 5;
}
.load-static img {
   width: 300px;
   position: absolute;
   top: 35vh;
   left: 41vw;
}
.load-static {
   width: 100vW;
   height: 100vh;
   background-color: #fff;
   position: fixed;
   top: 0;
   text-align: center;
   vertical-align: middle;
   z-index: 200;
}
#otp-button {
   width: 160px;
   padding: 0;
   margin: 10px;
   line-height: 1px;
   height: 40px;
}