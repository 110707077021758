


@media only screen and (min-width: 1500px) {
    .thankyou-box {
        margin-top: 120px;
    }
    .content-wrap.login {
        height: 95vh;
    }
    .content-inner.credit {
        min-height: 884px;
    }
}

@media only screen and (max-width: 1200px) {
    section.introwrap {
        margin-top: 45px;
    }

    section.introwrap h1 {
        font-size: 33px;
        line-height: 51px;
    }

    .intro-text {
        margin-left: 21px;
    }

    .contact-col1 {
        padding: 50px 50px 50px 50px;
    }
    .workspace {
        margin-left: 25px;
    }
}

@media only screen and (max-width: 991px) {
    span.navbar-brand {
        order: 2;
        left: -150px;
        position: relative;
    }

    .engage-image {
        margin-bottom: 55px;
        margin-top: 55px;
    }

    .btn-showall {
        width: 100%;
    }

    .hmabout-content {
        margin-left: 0;
        margin-top: 0;
    }

    .hmabout-content h5 {
        font-size: 28px;
        line-height: 38px;
    }

    .contact-col2 {
        max-width: 300px;
        width: 300px;
        margin-right: 30px;
    }

    .contact-col1 h4 {
        font-size: 34px;
        font-weight: 300;
        line-height: 50px;
        letter-spacing: 0.10000000149011612px;
    }

    .footer .navbar-nav {
        flex-direction: row;
    }

    section.hm-faq {
        margin-top: 30px;
    }
    .contact-col1 {
        padding: 50px 50px 0px 50px;
    }

    section.hm-about {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .navbar-expand-lg .container {
        justify-content: flex-start;
    }

    .footer .navbar-expand-lg .container {
        justify-content: space-between;
    }

    button.navbar-toggler {
        /* order: 1; */
        border: 0;
    }

    a.navbar-brand {
        order: 2;
    }

    .navbar-toggler:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: none !important;
    }

    .top-btn {
        order: 3;
        display: flex;
    }

    a.navbar-brand {
        /* order: 2; */
        position: absolute;
        left: 65px;
    }

    .navwrap {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-top: 10px;
    }

    div#basic-navbar-nav {
        position: absolute;
        top: 61px;
        background: #fff;
        z-index: 5;
        width: 100%;
        box-shadow: 6px 7px 9px #00000014;
        text-align: c;
        border: 1px solid #ccc;
        padding: 9px 11px;
    }

    .dropdown-toggle::after {
        margin-left: 48px;
    }

    .navbar-nav .dropdown-menu {
        position: static;
        border-radius: 0;
    }

    section.hm-contact-wrap {
        margin-bottom: 35px;
    }

    .strip-wrap {
        width: 100%;
    }

    .thankyou-wrap:before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 100%;
        height: 871px;
        background-image: url(./img/thankyou-bg.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform: translate(-50%, -50%);
    }

    .thankyou-box {
        margin: 108px auto 0 auto;
    }

    section.content-rows-wrap {
        margin-top: 55px;
    }

    .content-rows {
        max-width: 100%;
        margin: 0 auto 47px auto;
    }

    .content-rows.gfix {
        margin: 17px auto 54px 0px;
    }

    .faq-col1.boiler {
        margin-left: 0;
    }

    .faq-col2.boiler {
        margin-left: 0;
        margin-bottom: 51px;
    }

    .introwrap {
        margin-top: 50px;
    }

    section.content-rows-wrap.gfix {
        margin-top: 0;
        padding: 0 15px;
    }

    section.peopleask {
        padding: 0 15px;
    }

    .forgotpw-wrap {
        height: 100vh;
    }

    .fh-head-info {
        width: 100%;
    }

    .faqbox-wrap h4 {
        font-size: 28px;
    }

    .faqbox-wrap {
        position: relative;
        display: block;
        padding: 0 15px;
    }

    .faq-header {
        padding-top: 0;
        margin-bottom: 24px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .faqsection1 .mb-5 {
        margin-bottom: 15px !important;
    }

    .faqsection2 {
        padding-bottom: 45px;
    }
    .input-details-wrap {
        position: relative;
        display: block;
        max-width: 654px;
        width: 100%;
    }
    .output-detail-wrap {
        width: 90%;
        margin-top: 70px;
        padding-top: 50px;
    }
    .workspace {
        margin-left: 0;
    }
    .signbox-inner-wrap {
        margin-right: 0;
    }
    .footer a.navbar-brand {
        /* order: 2; */
        position: relative;
        left: 0;
        order: 0;
    }
    .login-box.regstep.tellmore:after {
        display: none;
    }
    .tellmore h1 {
        font-size: 30px;
    }
    .content-inner.credit {
        min-height: 854px;
    }
    .intro-text-heading  p {
        font-size: 32px;
    }
    .intro-text {
        margin-top: 0;
    }
    .btn.btn-primary {
        width: 100%;
        height: 47px;
    }
    .zig-title h3 {
        font-size: 30px;
    }
    .zig-title {
        margin-left: 0;
    }
    .zig-title.r2 h3 {
        margin-top: 0;
    }
    .shape6 {
        bottom: -315px;
    }
    .plans-wrap>.container {
        max-width: 100%;
        margin: 0 auto;
        background: #F0F1F566;
        border-radius: 48px;
        padding: 50px 50px;
    }
    .plans-wrap>.container {
        max-width: 100%;
    }
    .p-pricewrap h5 {
        font-size: 30px;
    }
    .plan-list ul li {
        font-size: 13px;
    }
    .faq-col1 h5, .faq-col1 h6 {
        font-size: 30px;
        line-height: 35px;
    }
    a.btn-showall.btn.btn-primary {
        height: auto;
    }
    .btn.btn-primary {
        width: 100%;
        height: auto;
    }
    .contact-col2 {
        max-width: 88%;
        width: 100%;
        margin-right: 0;
        margin: 40px auto 40px auto;
        float: none;
    }
    .contact-col1 h3 {
        font-size: 11px;
        margin-bottom: 15px;
    }
    .tbl-compare-plan h4 {
        font-size: 19px;
    }
    .tbl-compare-plan .table-striped>tbody>tr td {
        padding-right: 42px;
    }
    button.optionsbtn.btn.btn-primary {
        width: auto;
    }
    .tab-header-btnwrap button.btn.btn-primary, .tab-content-header.changepw button.btn.btn-primary{
        margin-bottom: 0px;
        width: auto;
    }
    .tab-content-header h3 {
        font-size: 14px;
    }

}

@media only screen and (max-width: 767px) {

    html,
    body {
        overflow-x: hidden;
    }

    .navbar-brand img {
        transform: scale(0.8);
        max-width: 100%;
    }

    a.dropdown-item {
        font-size: 14px;
        padding: 0 31px;
    }

    button.navbar-toggler {
        z-index: 99;
    }

    span.navbar-brand {
        left: -18px;
        position: relative;
    }

    /* .btn-sign {
        width: 82px;
    } */
    .top-btn {
        order: 3;
        display: flex;
        left: 0;
        position: relative;
    }

    .navbar-collapse.collapse.show {
        left: 0;
    }

    a.navbar-brand {
        left: 28px;
        width: 50%;
    }
    .btn-sign {
        font-size: 9px;       
        padding: 3px 17px;      
        width: 70px;
        height: 34px;
        line-height: 28px;        
    }
 

    .h-order1 {
        order: 1;
    }

    .engage-image img {
        height: 260px;
        object-fit: cover;
    }

    .engage-image {
        padding: 0 14px;
    }

    .engage-content-box h2, .engage-content-box h3, .engage-content-box p {
        text-align: center;
    }

    .engage-content-box {
        max-width: 100%;
        padding: 0 14px;
    }

    .intro-text {
        margin-left: 0;
        padding: 0 14px;
    }

    .ec-box {
        text-align: center;
        padding: 0 14px;
        margin-bottom: 65px;
        max-width: 100%;
    }
    .ec-box h4,
    .ec-box h5,
    .ec-box p {
        text-align: left;
    }
    .ec-box h5 {
        margin-bottom: 8px;
        text-align: center;
    }
    .ec-box h5 br {
        display: none;
    }

    .plan-header {
        text-align: left;
    }

    .plan-box-wrap .container {
        max-width: 100%;
    }

    .plans-wrap>.container {
        max-width: 100%;
        margin: 0 auto;
        padding: 50px 24px 20px 24px;
    }

    .plan-tbl .col-md-12 {
        padding: 15px 0;
    }

    .tbl-compare-plan .table-striped>tbody>tr td:last-child {
        padding-right: 8px;
    }

    .tbl-compare-plan .table-striped>thead>tr th:last-child {
        padding-right: 8px;
    }

    .tbl-compare-plan h4 {
        margin-top: 32px;
        font-size: 13px;
    }

    .tbl-compare-plan tr td {
        vertical-align: middle;
        padding: 1px 0 0 13px;
    }

    .tbl-compare-plan tr td {
        font-weight: 500;
        font-size: 13px;
    }


    .planbox {
        margin-bottom: 32px;
    }

    .tbl-compare-plan td h5 {
        padding-left: 36px;
        margin-left: 0;
    }

    .faq-col1 {
        position: relative;
        display: block;
        margin-bottom: 5px;
        padding: 0 15px;
    }

    .faq-col2 {
        padding: 0 15px;
    }

    .hmabout-content {
        padding: 0 20px;
    }

    .hmabout-image {
        display: none;
    }

    .btn-showall {
        width: 65%;
    }

    .contact-col1 {
        padding: 50px 14px 0;
    }

    .contact-col2 {
        max-width: 90%;
        width: 100%;
        margin-right: 0;
        float: none;
        margin: 10px auto 40px auto;
        padding: 40px 20px;
    }
    .contact-col2 h5 {
        font-size: 25px;
    }

    .footer .navbar-nav {
        order: 1;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        padding: 0 18px;
        margin-top: 30px;
    }
    .footer a.navbar-brand {
        order: 2;
        position: relative;
        left: 0;
        /* order: 0; */
    }

    .footer span.navbar-brand {
        order: 2;
    }

    p.copyright {
        margin-bottom: 0;
        order: 3;
    }

    .pop-col1 {
        margin-top: 0;
    }

    .modal-header {
        padding: 32px 14px 0;
    }

    button.btn-close {
        top: -20px;
        position: relative;
    }

    .pop-col1 p {
        margin-bottom: 32px;
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0.10000000149011612px;
    }

    .thankyou-box {
        width: 87%;
    }

    .thankinner h1 {
        font-size: 26px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0.10000000149011612px;
    }

    button.btn-use {
        width: 129px;
    }

    .thankyou-box {
        margin: 28px auto 0 auto;
    }

    button.btn-use {
        width: 147px;
    }

    button.btn-getstarted {
        width: 114px;
    }

    .thankyou-wrap:before {
        background-position: 26% 63px;
    }

    .btn-get-start {
        width: 250px;
    }

    .content-rows-image img {
        border-radius: 40px;
        height: 260px;
        object-fit: cover;
    }

    .faq-col2.default {
        margin-bottom: 0;
    }

    section.peopleask {
        margin-bottom: 100px;
    }

    .faq-col1 h5 {
        font-size: 26px;
        color: #011128;
    }

    .faq-col2 button.accordion-button.collapsed {
        padding: 20px;
        line-height: 28px;
    }

    .faq-col2 button.accordion-button {
        padding: 30px 20px 12px 20px;
    }

    .faq-col2 .accordion-item .accordion-body {
        padding: 0px 20px 24px 20px;
        font-size: 14px;
    }

    .introwrap h1 {
        font-size: 32px;
    }

    .content-rows h3 {
        font-size: 18px;
    }

    .boiler .content-rows-inner {
        margin-bottom: 18px;
    }

    section.peopleask {
        margin-bottom: 40px;
    }

    /* .navbar .nav-link {
        margin: 0 17px;
        left: 7px;
        font-size: 12px;
    } */
    .navbar .nav-link {
        margin: 0 13px 0 0;
        left: 7px;
        font-size: 12px;
    }
    .content-wrap.login {
        margin: 15px;
    }

    .login-wrap:before {
        top: 50%;
        left: 57%;
        width: 532px;
        height: 407px;
        background-size: 98%;
    }

    .loginwrap-inner {
        padding: 10px 25px 25px 25px;
    }

    .login-wrap {
        max-width: 100%;
        width: 100%;
    }

    .forgot-box-wrap h1 {
        margin-bottom: 27px;
    }

    .forgot-box-wrap {
        margin-top: 35px;
    }

    .forgot-text button.btn-close {
        top: -2px;
        left: 0;
        position: relative;
    }

    .forgot-box-head {
        flex-direction: column;
        align-items: center;
    }
    .mailfix {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #C7CFDD !important;
    }

    .f-header h1 {
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.10000000149011612px;
    }

    .f-header p {
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.10000000149011612px;
    }

    .headblock {
        position: relative;
        display: block;
        margin-left: 0;
        text-align: center;
    }

    .fh-head-info {
        text-align: center;
    }

    .faqbox-wrap h4 {
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.10000000149011612px;
        margin-bottom: 0;
    }

    .faqbox-wrap p {
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.10000000149011612px;
        color: #171717;
        opacity: 0.48;
        text-align: left;

    }

    .faq-right-column h4 {
        font-size: 26px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0.10000000149011612px;
        margin-bottom: 16px;
    }

    .sign-text {
        flex-direction: column;
    }

    .btn-getcredit {
        padding: 8px 24px;
        max-width: 160px;
        margin-top: 35px;
    }

    .progress {
        height: 4px;
        top: 0;
        position: relative;
    }

    .projectlist-wrap {
        margin-top: 16px;
        padding: 48px 4px 0;
    }

    .mobile-note {
        position: relative;
        display: flex;
        align-items: center;
        padding: 20px;
        border: 1px solid #A8C7FE;
        background: #F3FBFF;
        border-radius: 8px;
        color: #011128;
        margin: 15px 0;
        width: 100%;
        z-index: 3;
    }
    .mobile-note p {
        margin-bottom: 0;
        margin-left: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.10000000149011612px;

    }
    .p-search {
        margin-top: -5px;
    }

    .sign-text h4 {
        font-size: 18px;
        font-weight: 500;
    }


    .signbox-wrap {
        margin-bottom: 16px;
        max-width: 360px;
        margin: 0 auto 16px auto;
    }

    .project-tbl-wrap {
        display: none;
    }

    .project-list-mobile {
        display: block;
        background: #F0F1F5;
        padding-bottom: 74px;
    }
    .pl-mobile-box {
        position: relative;
        display: block;
        width: 83%;
        margin: 0 auto 16px auto;
        background: #fff;
        padding: 16px 32px;
        border-radius: 16px;
    }
    .loadmore-wrap {
        bottom: 15px;
        left: 38px;
    }
    .pl-mobile-box-row {
        position: relative;
        display: block;
        margin-bottom: 12px;
    }

    .pl-mobile-box-row h4 {
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0.10000000149011612px;
        margin-bottom: 0;
        opacity: 0.48;
    }

    .pl-mobile-box-row h5 {
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.10000000149011612px;
        margin-bottom: 0;
    }

    .pl-mobile-box-row .dropdown {
        float: right;
        right: 159px;
        position: relative;
    }
    button.sorting {
        top: 2px;
    }

    .inro-image {
        max-width: 75%;
        margin: -46px auto 0 auto;
    }

    /* .introwrap p {
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        margin-bottom: 32px;
    } */

    .engage-image {
        margin-bottom: 40px;
    }

    .engage-content-box h2 {
        margin-bottom: 4px;
        text-align: center;
    }

    .plan-header h5 {
        font-size: 17px;
        font-weight: 400;
        text-align: center;
    }

    .toggle-button.text-center {
        text-align: center!important;
    }

    section.plan-box-wrap {
        margin-bottom: 0;
    }

    .our-plan-title h4 {
        text-align: left;
    }

    .our-plan-title form {
        max-width: inherit;
        margin: 0 auto;
    }

    .tbl-compare-plan h6 {
        padding-left: 0;
        margin-top: 10px;
    }

    .hmabout-content h5 {
        font-size: 32px;
        line-height: 48px;
        color: #011128;
        margin-bottom: 8px;
    }

    .contact-col1 h4 {
        font-size: 24px;
        font-weight: 300;
        line-height: 40px;
    }

    .content-wrap.gray {
        margin-top: 20px;
    }

    .f-header.col1 {
        margin-top: 0;
        margin-bottom: 40px;
    }

    .faq-header {
        padding-top: 0;
        margin-bottom: 35px;
    }

    .headblock p {
        max-width: 100%;
        margin: 0 auto 30px auto;
        padding-bottom: 18px;
    }

    .fh-head-info .dk {
        display: none !important;
    }

    .fh-head-info .mobile {
        display: block !important;
        margin: 0 auto;
    }

    .loginbox h5 {
        margin-bottom: 30px;
    }

    .login-footer {
        padding: 24px 25px;
    }

    .forgot-box-head h3 {
        margin-bottom: 15px;
    }

    .forgot-text .form-control {
        font-size: 15px;
    }

    .forgot-text {
        margin-left: 15px;
        margin-right: 15px;
    }

    .profile-submenu .nav-item {
        background-position: 92% center;
        padding: 11px 11px 10px 13px;
     }

     .signbox-wrap h2 {
        font-size: 14px;
        margin-bottom: 0;
    }
    .stepline-wrap {
        position: relative;
        display: block;
        margin-top: -3px;
    }

    .project-title {
        padding: 0 18px;
    }
    .project-title h6 {
        font-size: 26px;
        font-weight: 600;
    }
    .modal-title h3 {
        font-size: 26px;
        font-weight: 600;
    }
    .checkout-package-box-content {
        padding: 25px;
    }
    .fade.modal.show {
        padding: 0 !important;
    }
    .modal.show .modal-dialog {
        transform: none;
        padding: 0 !important;
        margin: 0;
    }
    .modal-header button.btn-close {
        top: -11px;
    }
    .pop-col2 {
        margin-top: 30px;
    }
    .accordion-button:not(.collapsed)::after {
        transform: rotate(0deg);
    }
    .plan-tick {
        margin-bottom: 0;
    }
    .faq-col1 p {
        font-size: 14px;
    }
    .fh-head-info p {
        font-size: 16px;
    }
    .loginbox-form .btn-sign-in {
        margin: 40px auto 40px auto;
    }
    .modal-content {
        background: #F0F1F5;
        border-radius: 24px;
        margin-top: 86px;
    }
    .modal-backdrop {
        background: no-repeat;
    }

    .input-details-wrap {
        max-width: 83%;
        width: 100%;
    }
    .form-control.addval {
        margin-bottom: 15px;
    }
    .tagsInput {
        width: 100%;
        margin: 0 11px 15px 11px;
    }
    .catagory-value img {
        margin-left: 9px;
        cursor: pointer;
    }

    .export-data-wrap {
        width: 100% !important;
        top: 0;
        left: 0 !important;
        position: relative !important;
        margin-bottom: 15px;
    }
    .btn-wraps {
        position: relative;
        display: flex;
        flex-direction: column;
    }
    button.btn.btn-pgenerate {
        margin: 10px 0;
        padding: 11px 42px;
        max-width: 100%;
    }

    button.btn.btn-reset {      
        margin-left: 0;
        margin-bottom: 15px;
        padding: 9px 61px;
    }

    .no-data select.form-select {
        max-width: 100%;
    }
    .output-title-wrap {
        margin-bottom: 37px;
        flex-direction: column;
    }
    .output-title-wrap h3 {
        margin-bottom: 30px;
        font-size: 31px;
        font-weight: 500;
        color: #1e1f46;
    }
    .output-detail-wrap {
        width: 90%;
        margin-top: 23px;
        padding-top: 0;
    }
    .out-put-title {
        position: relative;
        display: block;
        margin-bottom: 30px;
    }
    button.btn.btn-feedback {
        max-width: 80%;
        margin: 10px auto;
    }

    .drop-wrap {
        position: relative;
        display: flex;
        flex-direction: column;
    }
    button.close-btn {
        margin-left: 0;
    }
    section.engage-contents-wrap {
        display: block;
        padding-bottom: 0;
        position: relative;
    }
    a.btn-next-step {
        margin: 0 auto;
        display: block;
        width: 190px;
    }
    .login-box.regstep.tellmore {
        padding: 30px;
    }
    .content-inner.credit {
        min-height: 500px;
    }
    .inro-image {
        z-index: 2;
    }

    .intro-bg {
        top: -310px;
    }
    .zig-title h3 {
        font-size: 30px;
        text-align: center;
        line-height: 41px;
    }
    .zig-title {
        margin-left: 0;
        margin-top: 30px;
    }
    .order1.col-md-6 {
        order: 2;
    }
    .zig-box.row {
        margin-bottom: 56px;
    }
    .plan-header h4 {
        font-size: 32px;
        line-height: 47px;
    }
    .plan-header h3 {
        text-align: center;
    }


   .tbl-compare-plan tr.gray td:nth-child(2) {
        display: none;
    }
    .tbl-compare-plan tr.gray td:nth-child(3) {
        display: none;
    }
    .tbl-compare-plan .table-striped>tbody>tr td {
        padding-right: 15px;
    }
    .tbl-compare-plan h6.popular {
        font-size: 10px;
        text-align: center;
        padding: 3px 10px;
    }

    .faq-col1 h5, .faq-col1 h6 {
        font-size: 32px;
        line-height: 49px;
    }
    .btn-getstart {
        margin-top: 15px;
    }
    .home-redirect {
        top: 5px;
        right: 5px;
    }
    .pl-mobile-box .btn-primary {
        width: 50px;
        height: 50px;
        background-color: transparent;
        right: 0;
    }
    .signbox-inner-wrap {
        margin-right: 0;
        flex-direction: column;
    }
    .signbox-wrap.credit, .signbox-wrap.storage {
        width: 100%;
    }

    .workspace .btn-getcredit {
        padding: 13px 24px;
        max-width: max-content;
        margin-top: 0;
        line-height: 11px;
        margin-bottom: 30px;
    }
    .checkwrap {
        width: 100%;
    }
    .checklist-row {
        display: block;
    }
    
    .form-check label.form-check-label {
        margin-left: 8px;
    }
    .input-details-wrap h3 {
        margin-bottom: 40px;
    }
    .ant-select.ant-tree-select.ant-select-outlined.css-dev-only-do-not-override-1xg9z9n.ant-select-multiple.ant-select-show-arrow.ant-select-show-search {
        height: 60px;
    }


}




@media only screen and (max-width: 360px) {}

/* content */
@media (min-width: 768px) and (max-width: 1024px) {
    .tag-input-button {
        display: inline-block;
    }
}