.contents {
    position: relative;
    display: block;
    height: auto;
}

.contents.reg {
    height: auto;
}

.color-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(200, 200, 200, 0.2);
}

form {
    background-color: white;
    box-shadow: 5px 10px 10px 10px rgb(0 0 0 / 6%);
}

.form-rounded {
    border-radius: 1rem;
}

.signin-lable {
    font-size: 25px;
    font-weight: 400;
}

.forgot-lable {
    font-size: 14px;
}
.validate {
    text-align: left;
    color: #f04a4a;
    font-size: 12px;
    margin: 8px 0 0 0;
}
.check-reg input{
    margin-left:0 !important;
    margin-right:10px ;
}